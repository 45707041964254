import { ViewContainerRef } from '@angular/core';
import { IButton } from 'src/app/api/modules/core/dynamic/components/IButton';
import { ButtonComponent } from './button.component';

/**
 * Dynamically creates an instance of the `ButtonComponent` and injects it into a specified container.
 *
 * This function allows you to programmatically create a `ButtonComponent` with a given configuration.
 * The component will be inserted into the provided `ViewContainerRef`, and the `config` will be applied to it.
 *
 * ### Parameters:
 * - **`viewContainerRef`**: The container where the `ButtonComponent` will be dynamically inserted. This is typically a `ViewContainerRef` in Angular, where components can be inserted dynamically.
 * - **`config`**: An `IButton` object that provides the configuration for the button component. This configuration will be applied to the dynamically created button instance.
 *
 * ### Returns:
 * - The function returns the **component reference** (`ComponentRef<ButtonComponent>`) of the dynamically created `ButtonComponent`. This can be used to access or manipulate the component instance.
 */
export const createButtonComponent = (viewContainerRef: ViewContainerRef, config: IButton) => {
  const componentReference = viewContainerRef.createComponent(ButtonComponent);
  const component = componentReference.instance;
  component.config = config;
  return componentReference;
};
