import { LoggerService } from '@angular-ru/cdk/logger';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import type {} from 'css-font-loading-module';
import { forkJoin, take, tap } from 'rxjs';
import { IDynamicFont } from 'src/app/api/modules/core/components/abstract/IDynamicFont';
import { IDynamicTheme } from 'src/app/api/modules/core/components/theme/IDynamicTheme';
import { DrimifyCustomTokens } from 'src/app/api/modules/core/components/theme/tokens/DrimifyTokens';
import { MaterialColorPalette } from 'src/app/api/modules/core/components/theme/tokens/MaterialColorTokens';
import {
  MaterialTypographyTokenKeysShorthand,
  MaterialTypographyTokens,
} from 'src/app/api/modules/core/components/theme/tokens/MaterialTypographyTokens';
import { createDigitaServiceError } from 'src/app/app-error';
import { DynamicThemeRepository } from './theme.repository';

/**
 * The Theme Service configures the CSS Variables.
 */
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  /**
   * Is Dark Mode enabled?
   */
  isDarkMode$ = this.dynamicThemeRepository.isDarkMode$;

  /**
   * Is High Contrast Mode enabled?
   */
  isHighContrastMode$ = this.dynamicThemeRepository.isHighContrastMode$;

  /**
   * The Active Palette.
   *
   * Could be light, dark, light high contrast, dark high contrast.
   */
  activePalette$ = this.dynamicThemeRepository.activePalette$;

  /**
   * Constructor
   */
  constructor(
    private readonly loggerService: LoggerService,
    private readonly dynamicThemeRepository: DynamicThemeRepository,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    // listen to the theme changes and apply them to the document.
    this.dynamicThemeRepository.theme$.subscribe((theme) => {
      // this.loggerService.groupCollapsed('[ThemeService] applyTheme');
      // this.loggerService.log('Theme', theme);
      // this.loggerService.close();

      // if there is no theme then abort
      if (!theme) {
        return;
      }

      // Get the root element (:root in CSS)
      const root = document.documentElement;

      // map all the colors to the CSS variables
      Object.keys(theme.activePalette).forEach((key) => {
        const value = theme.activePalette[key as keyof MaterialColorPalette];
        if (value) {
          root.style.setProperty(key, value);
        }
      });

      // typography
      if (theme.typography) {
        // Loop through all longhand keys in the typography object and set them as CSS variables
        Object.keys(theme.typography)
          .filter((key) => !(key in MaterialTypographyTokenKeysShorthand))
          .forEach((key) => {
            const value = theme.typography[key as keyof MaterialTypographyTokens];
            if (value) {
              root.style.setProperty(key, value);
            }
          });

        // Now update the shorthand keys in the typography object
        Object.keys(MaterialTypographyTokenKeysShorthand).forEach((tokenKey) => {
          // Construct the composite value using the expected naming pattern.
          const compositeValue = `var(${tokenKey}-weight) var(${tokenKey}-size) / var(${tokenKey}-line-height) var(${tokenKey}-font)`;
          // Apply the composite value to the CSS variable on the html element.
          document.documentElement.style.setProperty(tokenKey, compositeValue);
        });
      }

      // custom drimify tokens
      if (theme.drimify) {
        Object.keys(theme.drimify).forEach((key) => {
          const value = theme.drimify[key as keyof DrimifyCustomTokens];
          if (value) {
            root.style.setProperty(key, value);
          }
        });
      }
    });
  }

  /**
   * Applies a Theme to the Application.
   */
  applyTheme(theme: IDynamicTheme) {
    // if there is no theme then abort
    if (!theme) {
      return;
    }

    this.loggerService.log('[ThemeService] applyTheme');

    this.dynamicThemeRepository.applyConfiguration(theme);
  }

  /**
   * Toggle Dark Mode.
   */
  toggleDarkMode() {
    this.dynamicThemeRepository.toggleDarkMode();
  }

  /**
   * Toggle High Contrast Mode.
   */
  toggleHighContrastMode() {
    this.dynamicThemeRepository.toggleHighContrastMode();
  }

  /**
   * Set the Dark Mode to a specific value.
   *
   * @param value - true for Dark Mode, false for Light Mode
   */
  setDarkMode(value: boolean) {
    this.dynamicThemeRepository.setDarkMode(value);
  }

  /**
   * Set the High Contrast Mode to a specific value.
   *
   * @param value - true for High Contrast Mode, false for Normal Mode
   */
  setHighContrastMode(value: boolean) {
    this.dynamicThemeRepository.setHighContrastMode(value);
  }

  /**
   * Dynamically Load a Font into the System.
   *
   * @param fonts - the fonts to load.
   */
  loadFonts(fonts?: IDynamicFont[]) {
    if (!fonts || fonts.length === 0) {
      throw createDigitaServiceError('ThemeService', 'loadFonts', 'No fonts provided or the fonts array is empty.', 'config');
    }
    return this.loadFontsWithModern(fonts).pipe(
      take(1),
      tap((fonts) => {
        // for each of the fonts, add them to the document fonts.
        for (let i = 0; i < fonts.length; i++) {
          this.document.fonts.add(fonts[i]);
        }
      }),
    );
  }

  /**
   * Processes the fonts {@link IDynamicFont} array and converts them into an Observable.
   *
   * @param fonts - the fonts to load.
   */
  private loadFontsWithModern(fonts: IDynamicFont[]) {
    // this.loggerService.log('[ThemeService]::loadFontsWithModern', fonts);

    const fontFaces: FontFace[] = [];
    for (let i = 0; i < fonts.length; i++) {
      const font = fonts[i];
      const fontFamily = font.fontFamily;
      if (!fontFamily) {
        throw createDigitaServiceError('ThemeService', 'loadFontsWithModern', 'fontFamily is required for all dynamic fonts.', 'config');
      }

      // add the font files which need to be loaded to an array for processing.
      const fontURIs: string[] = [];
      if (!font.fontURIs) {
        throw createDigitaServiceError('ThemeService', 'loadFontsWithModern', 'fontURIs is required for all dynamic fonts.', 'config');
      }
      if (!font.fontURIs.woff2 && !font.fontURIs.woff && !font.fontURIs.ttf) {
        throw createDigitaServiceError(
          'ThemeService',
          'loadFontsWithModern',
          'Atleast one fontURI is required for all dynamic fonts.',
          'config',
        );
      }

      if (font.fontURIs.woff2) {
        fontURIs.push(`url(${font.fontURIs.woff2}) format("woff2")`);
      }
      if (font.fontURIs.woff) {
        fontURIs.push(`url(${font.fontURIs.woff}) format("woff")`);
      }
      if (font.fontURIs.ttf) {
        fontURIs.push(`url(${font.fontURIs.ttf}) format("truetype")`);
      }

      // add between these strings into a final large loading string similar to loading multiple files with css @font-face
      // we end up with either
      // "url(font.woff) format(woff)"
      // or multiple separated by a comma
      // "url(font.woff) format(woff), url(font.woff2) format(woff2)"
      let fontURI = ``;
      for (let j = 0; j < fontURIs.length; j++) {
        if (j === fontURIs.length - 1) {
          fontURI += `${fontURIs[j]}`;
        } else {
          fontURI += `${fontURIs[j]}, `;
        }
      }

      // the FontFace can be created.
      const fontFace = new FontFace(fontFamily, fontURI, font.descriptors);
      fontFaces.push(fontFace);
    }

    // returns a forkJoin of all the fonts to be loaded as an observable.
    return forkJoin(fontFaces.map((fontFace) => fontFace.load()));
  }
}
