/**
 * Defines all Drimify Tokens.
 *
 * This object serves as the authoritative source for Drimify-specific design tokens in JavaScript.
 * These tokens define various styling properties such as colors, borders, text alignment, and more.
 *
 * **Important:** This is the only place where Drimify Tokens should be listed in JavaScript.
 * Utility methods are used to generate the corresponding TypeScript interface.
 */
export const DrimifyCustomTokenKeys = {
  /**
   * Represents the CSS color value used to highlight a specific user in the leaderboard.
   * This value should be a valid CSS hex color value or point to a Material Design System variable.
   */
  '--ds-leaderboard-cell-user-highlight': undefined,

  /**
   * Represents the CSS color value used to indicate changes within a leaderboard cell.
   * This value should be a valid CSS hex color value or point to a Material Design System variable.
   */
  '--ds-leaderboard-cell-change-highlight': undefined,

  /**
   * Represents the CSS color value used for the border of a dynamic path node.
   * This value determines the border color of nodes in a dynamic path visualization.
   */
  '--ds-dynamic-path-node-border': undefined,

  /**
   * Represents the CSS color value used for SVG lines in a dynamic path visualization.
   * This value determines the color of the connecting lines.
   */
  '--ds-dynamic-path-svg-line': undefined,

  /**
   * Represents the CSS border-radius value applied to elements.
   * This defines the curvature of corners for elements that use this variable.
   */
  '--ds-radius': undefined,

  /**
   * Represents the CSS alignment value for large headlines.
   * This value determines the text alignment (e.g., left, center, right) of large headlines.
   */
  '--ds-headline-large-align': undefined,

  /**
   * Represents the CSS color value for large headlines.
   * This value should be a valid CSS hex color value or point to a Material Design System variable.
   */
  '--ds-headline-large-color': undefined,

  /**
   * Represents the CSS alignment value for medium-sized body text.
   * This value determines the text alignment (e.g., left, center, right) for medium-sized body content.
   */
  '--ds-body-medium-align': undefined,

  /**
   * Represents the CSS color value for medium-sized body text.
   * This value should be a valid CSS hex color value or point to a Material Design System variable.
   */
  '--ds-body-medium-color': undefined,

  /**
   * Represents the CSS color value used for button labels.
   * This value should be a valid CSS hex color value or point to a Material Design System variable.
   */
  '--ds-button-label-color': undefined,

  /**
   * Represents the CSS font-weight value for button labels.
   * This value determines the font weight (e.g., bold, normal) of button labels.
   */
  '--ds-button-label-weight': undefined,

  /**
   * Represents the CSS URL value for button textures.
   * This value specifies the URL of the texture or background image used for buttons.
   */
  '--ds-button-texture-url': undefined,

  /**
   * Represents the CSS color value used for the font color in plugin text.
   * This value should be a valid CSS hex color value or point to a Material Design System variable.
   */
  '--ds-plugin-font-color': undefined,

  /**
   * Represents the CSS font-family value used for plugin text.
   * This value should be a valid CSS font-family value or point to a Material Design System variable.
   */
  '--ds-plugin-font-family': undefined,

  /**
   * Represents the CSS font-size value used for plugin text.
   * This value should be a valid CSS font-size value or point to a Material Design System variable.
   */
  '--ds-plugin-font-weight': undefined,

  /***
   * Represents the CSS font-size value used for plugin text.
   * This value should be a valid CSS font-size value or point to a Material Design System variable.
   */
  '--ds-plugin-font-size': undefined,

  /**
   * Represents the CSS line-height value used for plugin text.
   * This value should be a valid CSS line-height value or point to a Material Design System variable.
   */
  '--ds-plugin-font-line-height': undefined,

  /**
   * Represents the CSS stroke width value used for plugin text.
   * This value should be a valid CSS stroke width value.
   */
  '--ds-plugin-font-stroke-width': undefined,

  /**
   * Represents the CSS color value used for the stroke of plugin text.
   * This value should be a valid CSS hex color value or point to a Material Design System variable.
   */
  '--ds-plugin-font-stroke-color': undefined,
} as const;

/**
 * Default Drimify Tokens.
 *
 * This object provides the default values for Drimify-specific design tokens.
 * These values ensure consistency across the Drimify platform while allowing customization.
 *
 * Some tokens reference Material Design System variables using CSS `var()` syntax.
 */
export const DefaultDrimifyCustomTokens: DrimifyCustomTokens = {
  '--ds-leaderboard-cell-user-highlight': 'var(--mat-sys-inverse-primary)',
  '--ds-leaderboard-cell-change-highlight': 'var(--mat-sys-surface-container-lowest)',
  '--ds-dynamic-path-node-border': 'var(--mat-sys-outline)',
  '--ds-dynamic-path-svg-line': 'var(--mat-sys-outline)',
  '--ds-radius': '10px',
  '--ds-headline-large-align': 'center',
  '--ds-headline-large-color': '',
  '--ds-body-medium-align': 'center',
  '--ds-body-medium-color': '',
  '--ds-button-label-color': '',
  '--ds-button-label-weight': '',
  '--ds-button-texture-url': 'none',
  '--ds-plugin-font-color': '#ffffff',
  '--ds-plugin-font-family': 'var(--mat-sys-display-small-font)',
  '--ds-plugin-font-weight': '900',
  '--ds-plugin-font-size': '40px',
  '--ds-plugin-font-line-height': '1.5',
  '--ds-plugin-font-stroke-width': '1px',
  '--ds-plugin-font-stroke-color': '#000000',
};

/**
 * Generates Drimify Tokens.
 *
 * This function takes an optional `DrimifyCustomTokens` object and merges it with the default tokens.
 * Additionally, it processes the `--ds-button-texture-url` value to ensure it is formatted correctly.
 *
 * @param custom - An object containing custom Drimify tokens.
 *
 * @returns The generated Drimify tokens, or `undefined` if no custom tokens are provided.
 */
export const GenerateDrimifyCustomTokens = (custom?: DrimifyCustomTokens) => {
  // if there is no custom, return the default
  if (!custom) {
    return undefined;
  }

  return {
    ...custom,
    '--ds-button-texture-url': processButtonTextureUrl(custom['--ds-button-texture-url']),
  };
};

/**
 * Ensures `--ds-button-texture-url` has a valid value.
 *
 * This function validates and processes the `--ds-button-texture-url` token.
 * If the value is `undefined`, `null`, or empty, it defaults to `'none'`.
 * If the value is not already wrapped in `url()`, it wraps it accordingly.
 *
 * @param value - The button texture URL to process.
 *
 * @returns The validated and formatted button texture URL.
 */
const processButtonTextureUrl = (value?: string) => {
  // Default to 'none' if undefined, null, or empty
  if (!value || value === 'none') {
    return 'none';
  }
  // Check if already wrapped in `url()`
  if (value.startsWith('url(')) {
    // Use as-is if already wrapped
    return value;
  }
  // Otherwise, wrap in `url()` if missing
  return `url(${value})`;
};

/**
 * Utility Type: Maps keys from a constant to a TypeScript type.
 *
 * This helper type dynamically maps keys from a given object type `T` to a new type
 * where each key is optional and its value is a `string`.
 */
type MappedInterface<T> = {
  [K in keyof T]?: string;
};

/**
 * Generated Interface for Drimify Tokens.
 *
 * This interface represents the generated Drimify tokens, extending the `DrimifyCustomTokenKeys`
 * structure while allowing optional properties. This ensures flexibility in defining custom tokens.
 */
export interface DrimifyCustomTokens extends Partial<MappedInterface<typeof DrimifyCustomTokenKeys>> {}
