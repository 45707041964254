import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDataboxScore } from 'src/app/api/modules/core/dynamic/databoxes/score/IDataboxScore';
import { DataboxScoreRepository } from './databox-score.repository';

@Component({
  selector: 'app-databox-score',
  templateUrl: './databox-score.component.html',
  styleUrls: ['./databox-score.component.scss'],
  providers: [DataboxScoreRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe],
})
export class DataboxScoreComponent {
  private _config?: IDataboxScore;
  /**
   * The Configuration powering the component.
   */
  @Input()
  set config(configuration: IDataboxScore) {
    this._config = configuration;
    this.databoxScoreRepository.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(public readonly databoxScoreRepository: DataboxScoreRepository) {}

  /**
   * Updates the component with the users score
   *
   * @param score - the score of the user
   */
  update(score?: number) {
    this.databoxScoreRepository.applyUpdate(score);
  }

  /**
   * Resets the DataBox to defaults.
   */
  reset() {
    this.databoxScoreRepository.applyReset();
  }
}
