import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDataboxTimer } from 'src/app/api/modules/core/dynamic/databoxes/timer/IDataboxTimer';
import { DataboxTimerRepository } from './databox-timer.repository';

@Component({
  selector: 'app-databox-timer',
  templateUrl: './databox-timer.component.html',
  styleUrls: ['./databox-timer.component.scss'],
  providers: [DataboxTimerRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe],
})
export class DataboxTimerComponent {
  private _config?: IDataboxTimer;
  /**
   * The Configuration powering the component.
   */
  @Input()
  set config(configuration: IDataboxTimer) {
    this._config = configuration;
    this.databoxScoreRepository.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(public readonly databoxScoreRepository: DataboxTimerRepository) {}

  /**
   * Splice the data into the template
   *
   * @param seconds - the gametime in seconds which will be formatted to the template
   */
  update(seconds?: number) {
    this.databoxScoreRepository.applyUpdate(seconds);
  }

  /**
   * Reset the timer to defaults.
   */
  reset() {
    this.databoxScoreRepository.applyReset();
  }
}
