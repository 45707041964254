/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { Subscription, tap } from 'rxjs';
import { IDataboxContainer } from 'src/app/api/modules/core/dynamic/databoxes/IDataboxContainer';
import { createDigitaServiceError } from 'src/app/app-error';
import { DynamicContentDirective } from 'src/app/modules/shared/directives/dynamic-content/dynamic-content.directive';
import { DataboxAttemptsIconComponent } from '../databox-attempts-icon/databox-attempts-icon.component';
import { DataboxHighScoreComponent } from '../databox-highscore/databox-highscore.component';
import { DataboxLivesIconComponent } from '../databox-lives-icon/databox-lives-icon.component';
import { DataboxProgressionIconComponent } from '../databox-progression-icon/databox-progression-icon.component';
import { DataboxProgressionNumericComponent } from '../databox-progression-numeric/databox-progression-numeric.component';
import { DataboxScoreComponent } from '../databox-score/databox-score.component';
import { DataboxTimerComponent } from '../databox-timer/databox-timer.component';
import { DataboxContainerFactoryArray } from './databox-container.factory';
import { DataboxContainerRepository } from './databox-container.repository';
import { DataboxContainerService } from './databox-container.service';

/**
 * DataBox Containers are responsible for holding a list of databox components
 */
@Component({
  selector: 'app-databox-container',
  templateUrl: './databox-container.component.html',
  styleUrls: ['./databox-container.component.scss'],
  providers: [DataboxContainerService, DataboxContainerRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FlexModule, DynamicContentDirective],
})
export class DataboxContainerComponent implements OnDestroy, AfterViewInit {
  /**
   * The Directive marked to place content dynamically
   */
  @ViewChild(DynamicContentDirective, { static: false }) contentContainer: DynamicContentDirective;

  /**
   * A reference to the dynamically created components.
   */
  private dynamicComponentRefs: ComponentRef<any>[] = [];

  /**
   * Configuration input for the component.
   */
  private _config: IDataboxContainer;
  @Input()
  set config(configuration: IDataboxContainer) {
    this._config = configuration;
    this.databoxContainerService.initialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * A subscription container for managing observables subscriptions.
   */
  protected subscriptions = new Subscription();

  /**
   * Constructor
   */
  constructor(
    public readonly databoxContainerRepository: DataboxContainerRepository,
    protected readonly databoxContainerService: DataboxContainerService,
    protected readonly cd: ChangeDetectorRef,
  ) {}

  ////////////////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // process the screen configuration
    const containerConfigurationSubscription = this.databoxContainerRepository.componentArray$
      .pipe(
        // configure the components
        tap((componentArray) => {
          // if the screen has a component array
          if (componentArray && Array.isArray(componentArray) && componentArray.length > 0) {
            // create the components
            this.createComponentsFromArray(componentArray);
          } else {
            throw createDigitaServiceError(
              `DataboxContainer`,
              `initialze`,
              `Databox Containers must be configured with a "componentArray" property`,
              `config`,
            );
          }
        }),
      )
      .subscribe(() => {
        this.cd.markForCheck();
      });
    this.subscriptions.add(containerConfigurationSubscription);
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * This method cleans up the dynamic component references by destroying them
   * and ensures that all subscriptions are unsubscribed to prevent memory leaks.
   */
  ngOnDestroy() {
    // Destroy all dynamic component references
    this.dynamicComponentRefs.forEach((item) => {
      item.destroy();
    });

    // Clear the array after destroying the components
    this.dynamicComponentRefs = [];

    // Unsubscribe from all subscriptions if they exist
    this.subscriptions?.unsubscribe();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // PROCESS
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Creates dynamic components from an array of configurations.
   *
   * @param componentArray - An array of configuration objects representing components.
   */
  protected createComponentsFromArray(componentArray: IDataboxContainer['componentArray']) {
    this.dynamicComponentRefs = DataboxContainerFactoryArray(this.contentContainer.viewContainerRef, componentArray);
  }

  ///////////////////////////////////////////////////////////////////////////
  // API
  ///////////////////////////////////////////////////////////////////////////

  /**
   * Updates the score for all DataboxScoreComponent instances.
   * It iterates through each component reference and calls the `update` method with the new score value.
   *
   * @param value - The new score value to be set.
   */
  scoreUpdate(value: number) {
    for (const item of this.dynamicComponentRefs) {
      if (item.instance instanceof DataboxScoreComponent) {
        item.instance.update(value);
      }
    }
  }

  /**
   * Updates the high score for all DataboxHighScoreComponent instances.
   * It iterates through each component reference and calls the `update` method with the new high score value.
   *
   * @param value - The new high score value to be set.
   */
  highScoreUpdate(value: number) {
    for (const item of this.dynamicComponentRefs) {
      if (item.instance instanceof DataboxHighScoreComponent) {
        item.instance.update(value);
      }
    }
  }

  /**
   * Updates the timer for all DataboxTimerComponent instances.
   * It iterates through each component reference and calls the `update` method with the new timer value.
   *
   * @param value - The new time value to be set.
   */
  timeUpdate(value: number) {
    for (const item of this.dynamicComponentRefs) {
      if (item.instance instanceof DataboxTimerComponent) {
        item.instance.update(value);
      }
    }
  }

  /**
   * Updates the numeric progression for all DataboxProgressionNumericComponent instances.
   * It iterates through each component reference and calls the `update` method with the current and total values.
   *
   * @param current - The current progress value.
   * @param total - The total progress value, optional.
   */
  progressNumericUpdate(current: number, total?: number) {
    for (const item of this.dynamicComponentRefs) {
      if (item.instance instanceof DataboxProgressionNumericComponent) {
        item.instance.update(current, total);
      }
    }
  }

  /**
   * Updates the icon progression for all DataboxProgressionIconComponent instances.
   * It iterates through each component reference and calls the `update` method with the current and total values.
   *
   * @param current - The current progress value.
   * @param total - The total progress value, optional.
   */
  progressIconUpdate(current: number, total?: number) {
    for (const item of this.dynamicComponentRefs) {
      if (item.instance instanceof DataboxProgressionIconComponent) {
        item.instance.update(current, total);
      }
    }
  }

  /**
   * Updates the lives icon for all DataboxLivesIconComponent instances.
   * It iterates through each component reference and calls the `update` method with the remaining lives.
   *
   * @param remainingLives - The number of remaining lives to be set.
   */
  livesIconUpdate(remainingLives: number) {
    for (const item of this.dynamicComponentRefs) {
      if (item.instance instanceof DataboxLivesIconComponent) {
        item.instance.update(remainingLives);
      }
    }
  }

  /**
   * Updates the attempts icon for all DataboxAttemptsIconComponent instances.
   * It iterates through each component reference and calls the `update` method with the new state list.
   *
   * @param stateList - The list of states to be set for the attempts.
   */
  attemptsIconUpdate(stateList: string[]) {
    for (const item of this.dynamicComponentRefs) {
      if (item.instance instanceof DataboxAttemptsIconComponent) {
        item.instance.update(stateList);
      }
    }
  }
}
