import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPromoCode } from 'src/app/api/modules/core/dynamic/components/IPromoCode';
import { PromoCodeRepository } from './promo-code.repository';

/**
 * The PromoCodeComponent is used to display a styized text representation of a promo code.
 *
 * It belongs to the {@link CoreModule}.
 */
@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss'],
  providers: [PromoCodeRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe],
})
export class PromoCodeComponent {
  /**
   * The Config as provided to the element
   */
  private _config?: IPromoCode;
  @Input()
  set config(configuration: IPromoCode) {
    this._config = configuration;
    this.promoCodeRepository.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(public readonly promoCodeRepository: PromoCodeRepository) {}
}
