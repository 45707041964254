<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->

<!-- Waits to be configured -->
@if (configuredState()) {
  <!-- If the Button is a Link -->
  @if (typeState() === 'link') {
    <!-- What style of button is it -->
    @switch (styleState()) {
      @case ('basic') {
        <a
          mat-button
          [matTooltip]="tooltipState()"
          [matTooltipPosition]="tooltipPositionState()"
          [class.mat-button-textured]="texturedState()"
          (click)="onClick($event)"
          [disabled]="disabledState()"
          [tabIndex]="tabIndexState()"
        >
          @if (hasLabelState()) {
            @if (hasIconPrefix()) {
              <mat-icon class="icon-prefix" [svgIcon]="iconPrefix()"></mat-icon>
            }
            {{ labelState() }}
            @if (hasIconPostfix()) {
              <mat-icon class="icon-postfix" [svgIcon]="iconPostfix()" iconPositionEnd></mat-icon>
            }
          }
        </a>
      }
      @case ('raised') {
        <a
          mat-raised-button
          [matTooltip]="tooltipState()"
          [matTooltipPosition]="tooltipPositionState()"
          [class.mat-button-textured]="texturedState()"
          (click)="onClick($event)"
          [disabled]="disabledState()"
          [tabIndex]="tabIndexState()"
        >
          @if (hasLabelState()) {
            @if (hasIconPrefix()) {
              <mat-icon class="icon-prefix" [svgIcon]="iconPrefix()"></mat-icon>
            }
            {{ labelState() }}
            @if (hasIconPostfix()) {
              <mat-icon class="icon-postfix" [svgIcon]="iconPostfix()" iconPositionEnd></mat-icon>
            }
          }
        </a>
      }
      @case ('flat') {
        <a
          mat-flat-button
          [matTooltip]="tooltipState()"
          [matTooltipPosition]="tooltipPositionState()"
          [class.mat-button-textured]="texturedState()"
          (click)="onClick($event)"
          [disabled]="disabledState()"
          [tabIndex]="tabIndexState()"
        >
          @if (hasLabelState()) {
            @if (hasIconPrefix()) {
              <mat-icon class="icon-prefix" [svgIcon]="iconPrefix()"></mat-icon>
            }
            {{ labelState() }}
            @if (hasIconPostfix()) {
              <mat-icon class="icon-postfix" [svgIcon]="iconPostfix()" iconPositionEnd></mat-icon>
            }
          }
        </a>
      }
      @case ('stroked') {
        <a
          mat-stroked-button
          [matTooltip]="tooltipState()"
          [matTooltipPosition]="tooltipPositionState()"
          [class.mat-button-textured]="texturedState()"
          (click)="onClick($event)"
          [disabled]="disabledState()"
          [tabIndex]="tabIndexState()"
        >
          @if (hasLabelState()) {
            @if (hasIconPrefix()) {
              <mat-icon class="icon-prefix" [svgIcon]="iconPrefix()"></mat-icon>
            }
            {{ labelState() }}
            @if (hasIconPostfix()) {
              <mat-icon class="icon-postfix" [svgIcon]="iconPostfix()" iconPositionEnd></mat-icon>
            }
          }
        </a>
      }
      @case ('icon') {
        <a
          mat-icon-button
          [matTooltip]="tooltipState()"
          [matTooltipPosition]="tooltipPositionState()"
          [class.mat-button-textured]="texturedState()"
          (click)="onClick($event)"
          [disabled]="disabledState()"
          [tabIndex]="tabIndexState()"
        >
          @if (hasIconPrefix()) {
            <mat-icon [svgIcon]="iconPrefix()"></mat-icon>
          }
        </a>
      }
      @case ('fab') {
        <a
          mat-fab
          [matTooltip]="tooltipState()"
          [matTooltipPosition]="tooltipPositionState()"
          [class.mat-button-textured]="texturedState()"
          (click)="onClick($event)"
          [disabled]="disabledState()"
          [tabIndex]="tabIndexState()"
          [extended]="extendedState()"
        >
          @if (hasLabelState()) {
            @if (hasIconPrefix()) {
              <mat-icon class="icon-prefix" [svgIcon]="iconPrefix()"></mat-icon>
            }
            {{ labelState() }}
            @if (hasIconPostfix()) {
              <mat-icon class="icon-postfix" [svgIcon]="iconPostfix()" iconPositionEnd></mat-icon>
            }
          } @else {
            @if (hasIconPrefix()) {
              <mat-icon [svgIcon]="iconPrefix()"></mat-icon>
            }
          }
        </a>
      }
      @case ('mini-fab') {
        <a
          mat-mini-fab
          [matTooltip]="tooltipState()"
          [matTooltipPosition]="tooltipPositionState()"
          [class.mat-button-textured]="texturedState()"
          (click)="onClick($event)"
          [disabled]="disabledState()"
          [tabIndex]="tabIndexState()"
        >
          @if (hasIconPrefix()) {
            <mat-icon [svgIcon]="iconPrefix()"></mat-icon>
          }
        </a>
      }
    }
  }
  <!-- Button Types use a <button> tag to execute local JS -->
  @else {
    @switch (styleState()) {
      @case ('basic') {
        <button
          mat-button
          [matTooltip]="tooltipState()"
          [matTooltipPosition]="tooltipPositionState()"
          [attr.form]="formState()"
          [class.mat-button-textured]="texturedState()"
          (click)="onClick($event)"
          [disabled]="disabledState()"
          [tabIndex]="tabIndexState()"
        >
          @if (hasLabelState()) {
            @if (hasIconPrefix()) {
              <mat-icon class="icon-prefix" [svgIcon]="iconPrefix()"></mat-icon>
            }
            {{ labelState() }}
            @if (hasIconPostfix()) {
              <mat-icon class="icon-postfix" [svgIcon]="iconPostfix()" iconPositionEnd></mat-icon>
            }
          }
        </button>
      }
      @case ('raised') {
        <button
          mat-raised-button
          [matTooltip]="tooltipState()"
          [matTooltipPosition]="tooltipPositionState()"
          [attr.form]="formState()"
          [class.mat-button-textured]="texturedState()"
          (click)="onClick($event)"
          [disabled]="disabledState()"
          [tabIndex]="tabIndexState()"
        >
          @if (hasLabelState()) {
            @if (hasIconPrefix()) {
              <mat-icon class="icon-prefix" [svgIcon]="iconPrefix()"></mat-icon>
            }
            {{ labelState() }}
            @if (hasIconPostfix()) {
              <mat-icon class="icon-postfix" [svgIcon]="iconPostfix()" iconPositionEnd></mat-icon>
            }
          }
        </button>
      }
      @case ('flat') {
        <button
          mat-flat-button
          [matTooltip]="tooltipState()"
          [matTooltipPosition]="tooltipPositionState()"
          [attr.form]="formState()"
          [class.mat-button-textured]="texturedState()"
          (click)="onClick($event)"
          [disabled]="disabledState()"
          [tabIndex]="tabIndexState()"
        >
          @if (hasLabelState()) {
            @if (hasIconPrefix()) {
              <mat-icon class="icon-prefix" [svgIcon]="iconPrefix()"></mat-icon>
            }
            {{ labelState() }}
            @if (hasIconPostfix()) {
              <mat-icon class="icon-postfix" [svgIcon]="iconPostfix()" iconPositionEnd></mat-icon>
            }
          }
        </button>
      }
      @case ('stroked') {
        <button
          mat-stroked-button
          [matTooltip]="tooltipState()"
          [matTooltipPosition]="tooltipPositionState()"
          [attr.form]="formState()"
          [class.mat-button-textured]="texturedState()"
          (click)="onClick($event)"
          [disabled]="disabledState()"
          [tabIndex]="tabIndexState()"
        >
          @if (hasLabelState()) {
            @if (hasIconPrefix()) {
              <mat-icon class="icon-prefix" [svgIcon]="iconPrefix()"></mat-icon>
            }
            {{ labelState() }}
            @if (hasIconPostfix()) {
              <mat-icon class="icon-postfix" [svgIcon]="iconPostfix()" iconPositionEnd></mat-icon>
            }
          }
        </button>
      }
      @case ('icon') {
        <button
          mat-icon-button
          [matTooltip]="tooltipState()"
          [matTooltipPosition]="tooltipPositionState()"
          [attr.form]="formState()"
          [class.mat-button-textured]="texturedState()"
          (click)="onClick($event)"
          [disabled]="disabledState()"
          [tabIndex]="tabIndexState()"
        >
          @if (hasIconPrefix()) {
            <mat-icon [svgIcon]="iconPrefix()"></mat-icon>
          }
        </button>
      }
      @case ('fab') {
        <button
          mat-fab
          [matTooltip]="tooltipState()"
          [matTooltipPosition]="tooltipPositionState()"
          [attr.form]="formState()"
          [class.mat-button-textured]="texturedState()"
          (click)="onClick($event)"
          [disabled]="disabledState()"
          [tabIndex]="tabIndexState()"
          [extended]="extendedState()"
        >
          @if (hasLabelState()) {
            @if (hasIconPrefix()) {
              <mat-icon class="icon-prefix" [svgIcon]="iconPrefix()"></mat-icon>
            }
            {{ labelState() }}
            @if (hasIconPostfix()) {
              <mat-icon class="icon-postfix" [svgIcon]="iconPostfix()" iconPositionEnd></mat-icon>
            }
          } @else {
            @if (hasIconPrefix()) {
              <mat-icon [svgIcon]="iconPrefix()"></mat-icon>
            }
          }
        </button>
      }
      @case ('mini-fab') {
        <button
          mat-mini-fab
          [matTooltip]="tooltipState()"
          [matTooltipPosition]="tooltipPositionState()"
          [attr.form]="formState()"
          [class.mat-button-textured]="texturedState()"
          (click)="onClick($event)"
          [disabled]="disabledState()"
          [tabIndex]="tabIndexState()"
        >
          @if (hasIconPrefix()) {
            <mat-icon [svgIcon]="iconPrefix()"></mat-icon>
          }
        </button>
      }
    }
  }
}
