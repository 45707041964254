import { LoggerService } from '@angular-ru/cdk/logger';
import { Injectable, OnDestroy } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { createStore, withProps } from '@ngneat/elf';
import { cloneDeep, uniqueId } from 'lodash-es';
import { ILink } from 'src/app/api/modules/core/components/abstract/ILink';
import { IDialogContent } from 'src/app/api/modules/core/components/dialog/IDialogContent';
import {
  ButtonStyleTypes,
  ButtonTypes,
  IButton,
  IButtonFeatureCopyToClipboard,
  OldButtonStyleTypes,
} from 'src/app/api/modules/core/dynamic/components/IButton';
import { IMatIcon } from 'src/app/api/modules/icons/mat/IMatIcon';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { ButtonModel } from './button.model';

/**
 * The Default State
 */
function initialState(): ButtonModel {
  return {
    configured: false,
    hasLabel: false,
    label: undefined,
    type: 'button',
    style: 'basic',
    hasIconPostfix: false,
    iconPostfix: undefined,
    hasIconPrefix: false,
    iconPrefix: undefined,
    link: undefined,
    hasClickToCloseFeature: false,
    hasCopyToClipboardFeature: false,
    hasPrintFeature: false,
    copyToClipboard: undefined,
    isTextured: undefined,
    hasDialog: false,
    dialog: undefined,
    disabled: false,
    belongsToForm: false,
    form: undefined,
    extended: false,
    tabIndex: undefined,
    isFixedSize: false,
    isFullSize: false,
    tooltip: undefined,
    tooltipPosition: 'below',
  };
}

/**
 * The `ButtonRepository` is a state management service that handles the configuration and state
 * of a button used within the `ButtonComponent`. It uses the `Elf` state management library to
 * store and manage button properties, making it easy to dynamically apply and track button
 * configurations such as labels, styles, features, and more.
 *
 * ### Key Features:
 * - Stores and manages the configuration of a button (e.g., label, type, style).
 * - Supports dynamic configuration updates and state management using `Elf`.
 * - Allows for button-specific configurations such as tooltips, icons, form associations,
 *   and feature toggles (e.g., Click-to-Close, Copy-to-Clipboard, Print).
 * - Provides reactive observables to listen for changes to the button state, making it
 *   ideal for binding with Angular templates.
 * - Supports a lifecycle for destroying and cleaning up the button's state when the component is
 *   destroyed.
 *
 * ### Dependencies:
 * - Uses `ButtonService` for applying configurations.
 * - The `Elf` state management library handles the state of the button.
 * - `LoggerService` is used for logging deprecated configurations and warnings.
 */
@Injectable()
export class ButtonRepository implements OnDestroy {
  /**
   * The store that holds the button's state.
   */
  private store = createStore(
    {
      name: `button-${uniqueId()}`,
    },
    withProps<ButtonModel>(initialState()),
  );

  constructor(private readonly loggerService: LoggerService) {}

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Applies the incoming configuration to the button model.
   *
   * This method allows dynamic configuration of the button, including setting properties like
   * the button type, style, label, icons, and features.
   * It also handles deprecated styles and ensures the button's properties are applied correctly.
   *
   * @param configuration - The configuration to apply to the button (optional).
   */
  applyInitialize(configuration?: Partial<IButton>) {
    // if no configuration was provided, then that is a problem.
    if (!configuration) {
      throw createDigitaServiceError(`ButtonStore`, `applyInitialize`, `No configuration provided`, `config`);
    }

    // button type
    let type: ButtonTypes = 'button';
    if (configuration.type === 'link') {
      if (configuration.link?.href) {
        type = 'link';
      } else {
        this.loggerService.warn(
          `[Button] - The configured button with 'type: "link"' was requested but no 'link' property was configured.`,
        );
      }
    }

    // the default is `basic`
    let style: ButtonStyleTypes | OldButtonStyleTypes = configuration.style || 'basic';

    // firstly deal with the deprecated styles
    switch (style) {
      case `mat-button`:
        this.loggerService.warn(`[Button] - The type "mat-button" is deprecated and should be replaced with "basic".`);
        style = 'basic';
        break;
      case `mat-stroked-button`:
        this.loggerService.warn(`[Button] - The type "mat-stroked-button" is deprecated and should be replaced with "stroked".`);
        style = 'stroked';
        break;
      case `mat-raised-button`:
        this.loggerService.warn(`[Button] - The type "mat-raised-button" is deprecated and should be replaced with "raised".`);
        style = 'raised';
        break;
      case `mat-flat-button`:
        this.loggerService.warn(`[Button] - The type "mat-flat-button" is deprecated and should be replaced with "flat".`);
        style = 'flat';
        break;
      case `mat-icon-button`:
        this.loggerService.warn(`[Button] - The type "mat-icon-button" is deprecated and should be replaced with "icon".`);
        style = 'icon';
        break;
      case `mat-fab`:
        this.loggerService.warn(`[Button] - The type "mat-fab" is deprecated and should be replaced with "fab".`);
        style = 'fab';
        break;
      case `mat-mini-fab`:
        this.loggerService.warn(`[Button] - The type "mat-mini-fab" is deprecated and should be replaced with "mini-fab".`);
        style = 'mini-fab';
        break;
    }

    // now deal with the modern styles
    switch (style) {
      case `basic`:
      case `raised`:
      case `flat`:
      case `stroked`:
      case `icon`:
      case `fab`:
      case `mini-fab`:
        break;
      default:
        this.loggerService.warn(`[Button] - The configured button style "${style}" is not supported and has been changed to "basic".`);
        style = 'basic';
        break;
    }

    // extended (used for mat-fab to allow them to grow with a label)
    let extended = false;
    if (style === 'fab' && configuration.extended === true) {
      extended = true;
    }

    // button color

    if (configuration.color) {
      this.loggerService.warn(
        `[Button] - The "color" property is no longer supported and has been ignored. Remove it from the configuration.`,
      );
    }

    // button label
    let hasLabel = false;
    let label: string | undefined = undefined;
    if (configuration.label) {
      if (style === `icon` || style === `mini-fab` || (style === `fab` && !extended)) {
        this.loggerService.warn(
          `[Button] - The "label" property is not allowed for buttons with style "icon", "mini-fab", or "fab" with "extended: false". It has been ignored.`,
        );
      } else if (style === `basic` || style === `raised` || style === `flat` || style === `stroked` || style === `fab`) {
        if (typeof configuration.label === 'string' && configuration.label.length > 0) {
          hasLabel = true;
          label = configuration.label;
        }
      }
    } else {
      if (style === `basic` || style === `raised` || style === `flat` || style === `stroked`) {
        this.loggerService.warn(
          `[Button] - The "label" property is required for basic, raised, flat, stroked buttons. It should be added.`,
        );
      }
    }

    // icon
    if (configuration.icon) {
      this.loggerService.warn(
        `[Button] - The "icon" property is no longer supported. You should use "iconPrefix" or "iconPostfix" instead.`,
      );
    }

    // icon prefix
    let hasIconPrefix = false;
    let iconPrefix: IMatIcon | undefined = undefined;
    if (configuration.iconPrefix) {
      hasIconPrefix = true;
      iconPrefix = cloneDeep(configuration.iconPrefix);
    }

    // icon postfix
    let hasIconPostfix = false;
    let iconPostfix: IMatIcon | undefined = undefined;
    if (configuration.iconPostfix) {
      hasIconPostfix = true;
      iconPostfix = cloneDeep(configuration.iconPostfix);
    }

    // if the style of the button is `icon`, `fab` (extended: false), or `mini-fab`
    if (style === `icon` || (style === `fab` && !extended) || style === `mini-fab`) {
      // if there is both a prefix and postfix icon then use the prefix
      if (hasIconPrefix && hasIconPostfix) {
        hasIconPostfix = false;
        iconPostfix = undefined;
      } else if (!hasIconPrefix && hasIconPostfix) {
        hasIconPrefix = true;
        iconPrefix = iconPostfix;
        hasIconPostfix = false;
        iconPostfix = undefined;
      }
    }

    // check for iconPrefix.color deprecation
    if (hasIconPrefix && iconPrefix?.color) {
      this.loggerService.warn(`[Button] - The "iconPrefix.color" property is deprecated on buttons. Remove it from the configuration.`);
    }
    // check for iconPostfix.color deprecation
    if (hasIconPostfix && iconPostfix?.color) {
      this.loggerService.warn(`[Button] - The "iconPostfix.color" property is deprecated on buttons. Remove it from the configuration.`);
    }

    // check for iconPrefix.align deprecation
    if (hasIconPrefix && iconPrefix?.align) {
      this.loggerService.warn(`[Button] - The "iconPrefix.align" property is deprecated on buttons. Remove it from the configuration.`);
    }
    // check for iconPostfix.align deprecation
    if (hasIconPostfix && iconPostfix?.align) {
      this.loggerService.warn(`[Button] - The "iconPostfix.align" property is deprecated on buttons. Remove it from the configuration.`);
    }

    // link
    let link: ILink | undefined = undefined;
    if (type === 'link' && configuration.link) {
      link = cloneDeep(configuration.link);
    }

    // feature
    let hasCopyToClipboardFeature = false;
    let copyToClipboard: IButtonFeatureCopyToClipboard | undefined = undefined;
    let hasClickToCloseFeature = false;
    let hasPrintFeature = false;
    if (configuration.feature) {
      if (configuration.feature.copyToClipboard) {
        hasCopyToClipboardFeature = true;
        copyToClipboard = cloneDeep(configuration.feature.copyToClipboard);
      } else if (configuration.feature.clickToClose) {
        hasClickToCloseFeature = true;
      } else if (configuration.feature.print) {
        hasPrintFeature = true;
      }
    }

    // isTextured
    let isTextured = false;
    if (configuration.isTextured) {
      isTextured = configuration.isTextured;
    }

    // dialog
    let hasDialog = false;
    let dialog: IDialogContent | undefined = undefined;
    if (configuration.dialog) {
      hasDialog = true;
      dialog = cloneDeep(configuration.dialog);
    }

    // tooltip
    let tooltip: string | undefined = undefined;
    if (configuration.tooltip) {
      tooltip = configuration.tooltip;
    }

    // tooltip position
    let tooltipPosition: TooltipPosition = 'below';
    if (configuration.tooltipPosition) {
      tooltipPosition = configuration.tooltipPosition;
    }

    // tab index
    let tabIndex: number | undefined = undefined;
    if (typeof configuration.tabIndex === 'number') {
      tabIndex = configuration.tabIndex;
    }

    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.hasLabel = hasLabel;
        state.label = label;
        state.type = type;
        state.style = style;
        state.hasIconPrefix = hasIconPrefix;
        state.iconPrefix = iconPrefix;
        state.hasIconPostfix = hasIconPostfix;
        state.iconPostfix = iconPostfix;
        state.link = link;
        state.hasClickToCloseFeature = hasClickToCloseFeature;
        state.hasCopyToClipboardFeature = hasCopyToClipboardFeature;
        state.copyToClipboard = copyToClipboard;
        state.hasPrintFeature = hasPrintFeature;
        state.isTextured = isTextured;
        state.hasDialog = hasDialog;
        state.dialog = dialog;
        state.extended = extended;
        state.tabIndex = tabIndex;
        state.tooltip = tooltip;
        state.tooltipPosition = tooltipPosition;
      }),
    );
  }

  /**
   * Lifecycle hook to destroy the store and clean up resources when the component is destroyed.
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  /**
   * Returns the whole store as an observable.
   */
  store$ = this.store.asObservable();
}
