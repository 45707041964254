/* eslint-disable @typescript-eslint/no-explicit-any */

import { AsyncPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ComponentRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { IButtonContainer } from 'src/app/api/modules/core/dynamic/containers/IButtonContainer';
import { DynamicContentDirective } from 'src/app/modules/shared/directives/dynamic-content/dynamic-content.directive';
import { ButtonComponent } from '../../components/button/button.component';
import { ContainerFactoryArray } from '../container/container.factory';
import { ButtonContainerRepository } from './button-container.repository';
import { ButtonContainerService } from './button-container.service';

@Component({
  selector: 'app-button-container',
  templateUrl: './button-container.component.html',
  styleUrls: ['./button-container.component.scss'],
  providers: [ButtonContainerService, ButtonContainerRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FlexModule, DynamicContentDirective, AsyncPipe],
})
export class ButtonContainerComponent implements OnDestroy, AfterViewInit {
  /**
   * The Directive marked to place content dynamically
   */
  @ViewChild(DynamicContentDirective, { static: false }) contentContainer: DynamicContentDirective;

  /**
   * The Screen Configuration
   */
  private _config: IButtonContainer;
  @Input()
  set config(configuration: IButtonContainer) {
    this._config = configuration;
    this.service.initialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * Any active descriptions to be destroyed
   */
  private subscriptions = new Subscription();

  /**
   * A Reference to all dynamically created components
   *
   * Used for destroying them or finding them
   */
  private dynamicComponentRefs: ComponentRef<any>[] = [];

  /**
   * Override Constructor
   */
  constructor(
    private readonly mediaObserver: MediaObserver,
    private readonly service: ButtonContainerService,
    public readonly repository: ButtonContainerRepository,
  ) {}

  ////////////////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // listen for media changes
    const media = this.mediaObserver
      .asObservable()
      .pipe(
        filter((changes) => changes.length > 0),
        map((changes) => changes[0]),
      )
      .subscribe((change) => {
        this.service.mediaChange(change);
      });
    this.subscriptions.add(media);

    // listen for button sizing changes
    const sizingSub = this.repository.buttonSizing$.subscribe((sizing) => {
      this.updateFixedStateForButtons(sizing.isFixedSize);
      this.updateFullStateForButtons(sizing.isFullSize);
    });
    this.subscriptions.add(sizingSub);

    // process the screen configuration
    const componentSubscription = this.repository.componentArray$
      .pipe(
        // configure the components
        tap((componentArray) => {
          // Destroy any existing dynamic components before creating new ones.
          this.dynamicComponentRefs.forEach((ref) => ref.destroy());
          this.dynamicComponentRefs = this.createComponentsFromArray(componentArray);
        }),
        switchMap(() => {
          return this.repository.buttonSizing$;
        }),
      )
      .subscribe((buttonSizing) => {
        this.updateFixedStateForButtons(buttonSizing.isFixedSize);
        this.updateFullStateForButtons(buttonSizing.isFullSize);
      });
    this.subscriptions.add(componentSubscription);
  }

  /**
   * Update the fixed state for all buttons
   *
   * @param isFixedSize - whether the buttons are fixed size
   */
  private updateFixedStateForButtons(isFixedSize: boolean) {
    this.dynamicComponentRefs.forEach((ref) => {
      if (ref.instance instanceof ButtonComponent) {
        ref.instance.fixedSize = isFixedSize;
      }
    });
  }

  /***
   * Update the full state for all buttons
   *
   * @param isFullSize - whether the buttons are full size
   */
  private updateFullStateForButtons(isFullSize: boolean) {
    this.dynamicComponentRefs.forEach((ref) => {
      if (ref.instance instanceof ButtonComponent) {
        ref.instance.fullSize = isFullSize;
      }
    });
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    // destroy all components
    this.dynamicComponentRefs.forEach((item) => {
      item.destroy();
    });
    // clear the array
    this.dynamicComponentRefs = [];

    // unsubscribe from all subscriptions
    this.subscriptions?.unsubscribe();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // PROCESS
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Creates Dynamic Components from an Array.
   *
   * Intended to be overridden.
   *
   * @param componentArray - the component array.
   */
  private createComponentsFromArray(componentArray: IButtonContainer['componentArray']): ComponentRef<any>[] {
    return ContainerFactoryArray(this.contentContainer.viewContainerRef, componentArray);
  }
}
