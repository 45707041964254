/* eslint-disable @typescript-eslint/no-explicit-any */

import { LoggerService } from '@angular-ru/cdk/logger';
import { Clipboard } from '@angular/cdk/clipboard';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, Inject, Input, OnDestroy, Output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  MatAnchor,
  MatButton,
  MatFabAnchor,
  MatFabButton,
  MatIconAnchor,
  MatIconButton,
  MatMiniFabAnchor,
  MatMiniFabButton,
} from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';
import { IButton } from 'src/app/api/modules/core/dynamic/components/IButton';
import { AppService } from '../../../services/application/application.service';
import { DialogService } from '../../../services/dialog.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { ButtonRepository } from './button.repository';
import { ButtonService } from './button.service';

/**
 * The `ButtonComponent` is a reusable button component that supports various configurations,
 * features, and behaviors, making it flexible for different UI requirements.
 *
 * ### Key Features:
 * - Supports both **button** and **link** types.
 * - Can be dynamically configured via the `config` input.
 * - Supports **tooltips**, **icons**, and **form association**.
 * - Handles special behaviors such as:
 *   - **Click-to-Close**
 *   - **Copy-to-Clipboard**
 *   - **Print Feature**
 *   - **Dialogs for confirmation**
 * - Uses **RxJS observables** to manage button states and interactions.
 * - **Optimized for performance** with `ChangeDetectionStrategy.OnPush`.
 *
 * ### Dependencies:
 * - Uses Angular Material components (`MatButton`, `MatTooltip`, `MatIcon`, etc.).
 * - Injects services like `ButtonService`, `ButtonRepository`, and `SnackbarService`.
 *
 * ### References:
 * - See {@link IButton} for button configuration options.
 * - See [MDN: `<button>` element](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button).
 * - See [Angular Material Button Documentation](https://material.angular.io/components/button/overview).
 */
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  providers: [ButtonService, ButtonRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatAnchor,
    MatIcon,
    MatIconAnchor,
    MatFabAnchor,
    MatMiniFabAnchor,
    MatButton,
    MatIconButton,
    MatFabButton,
    MatMiniFabButton,
    MatTooltip,
  ],
  host: {
    '[class.full-size]': '_fullSize()',
    '[class.fixed-size]': '_fixedSize()',
  },
})
export class ButtonComponent implements OnDestroy {
  /**
   * Defines the **configuration object** that powers this button component.
   *
   * This property:
   * - Accepts an `IButton` object to **dynamically configure** the button.
   * - Passes the configuration to the **button service** for initialization.
   * - Retrieves the current configuration of the button.
   *
   * ### Behavior:
   * - If a configuration is provided, the button is **initialized** with the specified properties.
   *
   * ### References:
   * - See {@link IButton} for available configuration options.
   */
  @Input()
  set config(configuration: IButton) {
    this._config.set(configuration);
    this.buttonService.applyInitialize(configuration);
  }
  get config() {
    return this._config();
  }

  private _hasPrintFeature = computed(() => this._model()?.hasPrintFeature);

  /////////////////////////////////////////////////////////////////////////////////
  // CONFIGURED PROPERTIES (from JSON)
  /////////////////////////////////////////////////////////////////////////////////
  // derive the model from the store
  readonly _config = signal<IButton | undefined>(undefined);
  private _model = toSignal(this.buttonRepository.store$);

  private _modelTooltip = computed(() => this._model()?.tooltip);
  private _modelTooltipPosition = computed(() => this._model()?.tooltipPosition);

  /**
   * Does the button have a feature?
   */
  private _hasFeature = computed(
    () => this._model()?.hasClickToCloseFeature || this._model()?.hasCopyToClipboardFeature || this._model()?.hasPrintFeature,
  );

  /**
   * Does the button have a click-to-close feature?
   */
  private _hasClickToCloseFeature = computed(() => this._model()?.hasClickToCloseFeature);

  /**
   * Does the button have a copy-to-clipboard feature?
   */
  private _hasCopyToClipboardFeature = computed(() => this._model()?.hasCopyToClipboardFeature);

  /**
   * The information for the copy-to-clipboard feature.
   */
  private _copyToClipboard = computed(() => this._model()?.copyToClipboard);

  /**
   * Does the button have an associated dialog?
   */
  private _hasDialog = computed(() => this._model()?.hasDialog);

  /**
   * What is the dialog associated with this button?
   */
  private _dialog = computed(() => this._model()?.dialog);

  /**
   * What is the link associated with this button? (if any)
   */
  private _link = computed(() => this._model()?.link);

  /////////////////////////////////////////////////////////////////////////////////
  // PRIVATE PROPERTIES
  /////////////////////////////////////////////////////////////////////////////////

  private belongsToForm = signal<boolean>(false);

  /**
   * The button's fixed size state, which determines whether to apply the host class.
   */
  private _fixedSize = signal<boolean>(false);

  /**
   * The button's full size state, which determines whether to apply the host class.
   */
  private _fullSize = signal<boolean>(false);

  /**
   * Controls whether there is a tooltip applied to the button directly via the @input
   * property. Since the tooltip can be set via the model also, there is a need to
   * differentiate between the two.
   */
  private _inputTooltip = signal<string | undefined>(undefined);

  /**
   * The position of the tooltip, which can be set via the @input property. Since the
   * tooltip position can be set via the model also, there is a need to differentiate
   * between the two.
   */
  private _inputTooltipPosition = signal<TooltipPosition | undefined>(undefined);

  /**
   * Indicates whether the button is currently processing an action. When true, additional
   * clicks are ignored.
   */
  private _processing = signal<boolean>(false);

  /////////////////////////////////////////////////////////////////////////////////
  // TEMPLATE PROPERTIES
  /////////////////////////////////////////////////////////////////////////////////

  /**
   * If the model has been set, this will return true otherwise false.
   */
  readonly configuredState = computed(() => !!this._model());

  /**
   * The button type, which can be either `"button"` or `"link"`.
   */
  readonly typeState = computed(() => this._model()?.type);

  /**
   * The button style, for example 'basic' or 'fab' etc.
   */
  readonly styleState = computed(() => this._model()?.style);

  /**
   * The Tooltip text for the button (if provided).
   */
  readonly tooltipState = computed(() => this._inputTooltip() ?? this._modelTooltip());

  /**
   * The Tooltip position for the button (if provided).
   */
  readonly tooltipPositionState = computed(() =>
    this._inputTooltip() && this._inputTooltipPosition() ? this._inputTooltipPosition() : this._modelTooltipPosition(),
  );

  /**
   * Is the button textured?
   */
  readonly texturedState = computed(() => !!this._model()?.isTextured);

  /**
   * Is the button disabled?
   */
  readonly disabledState = signal<boolean>(false);

  /**
   * The Tab Index for the button, which determines its order in the tabbing sequence.
   */
  readonly tabIndexState = computed(() => this._model()?.tabIndex);

  /**
   * Does the button have a label?
   */
  readonly hasLabelState = computed(() => this._model()?.hasLabel);

  /**
   * The label for the button, which is displayed when the button is not an icon-only button.
   */
  readonly labelState = computed(() => this._model()?.label);

  /**
   * Does this button belong to a form and if so, what is the name of the form?
   */
  readonly formState = signal<string | undefined>(undefined);

  /**
   * Is there an icon prefix?
   */
  readonly hasIconPrefix = computed(() => this._model()?.hasIconPrefix);

  /**
   * The icon prefix for the button, which is displayed before the label.
   */
  readonly iconPrefix = computed(() => this._model()?.iconPrefix?.name);

  /**
   * Is there an icon postfix?
   */
  readonly hasIconPostfix = computed(() => this._model()?.hasIconPostfix);

  /**
   * The icon postfix for the button, which is displayed after the label.
   */
  readonly iconPostfix = computed(() => this._model()?.iconPostfix?.name);

  /**
   * Is the button extended? This is used for extended FAB buttons.
   */
  readonly extendedState = computed(() => this._model()?.extended);

  /**
   * Controls whether the button is disabled.
   *
   * - Can be set dynamically via `@Input()`.
   * - Disables interaction and applies the state using the button service.
   * - `true` → disabled (not clickable), `false` → enabled (default).
   *
   * @see [MDN: <button> element](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button)
   */
  @Input()
  set disabled(isDisabled: boolean) {
    this.disabledState.set(isDisabled);
  }
  get disabled() {
    return this.disabledState();
  }

  /**
   * Associates the button with a form by name or ID.
   *
   * - If outside a `<form>`, this links the button to a form element.
   * - Enables triggering the form's `submit` event on click.
   * - Managed internally by the button service.
   *
   * @see [MDN: <button> element](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button)
   */
  @Input()
  set form(formName: string) {
    if (formName) {
      this.formState.set(formName);
      this.belongsToForm.set(true);
    } else {
      this.formState.set(undefined);
      this.belongsToForm.set(false);
    }
  }
  get form() {
    return this.formState();
  }

  /**
   * Whether the button should maintain a fixed width inside a Button Container.
   *
   * - Can be set dynamically via `@Input()`.
   * - Applied via the button service.
   * - Synced with the button repository.
   *
   * `true` → Fixed width
   * `false` → Width adapts to content (default)
   */
  @Input() set fixedSize(value: boolean) {
    this._fixedSize.set(value);
  }
  get fixedSize() {
    return this._fixedSize();
  }

  /**
   * Whether the button should maintain a full width inside a Button Container.
   *
   * - Can be set dynamically via `@Input()`.
   * - Applied via the button service.
   * - Synced with the button repository.
   *
   * `true` → Full width
   * `false` → Width adapts to content (default)
   */
  @Input() set fullSize(value: boolean) {
    this._fullSize.set(value);
  }
  get fullSize() {
    return this._fullSize();
  }

  /**
   * Tooltip text for the button.
   *
   * - Set dynamically via `@Input()`.
   * - Managed by the button service.
   * - Used to show a tooltip on hover.
   *
   * @see [Angular Material Tooltip](https://material.angular.io/components/tooltip/overview)
   */
  @Input()
  set tooltip(tooltipLabel: string | undefined) {
    this._inputTooltip.set(tooltipLabel);
    // since no input tooltip is set, the position should fall back to the model
    if (!tooltipLabel) {
      this._inputTooltipPosition.set(undefined);
    }
  }
  get tooltip() {
    return this._inputTooltip();
  }

  /**
   * Position of the tooltip relative to the button.
   *
   * - Set dynamically via `@Input()`.
   * - Defaults to `'below'` if undefined.
   * - Managed by the button service.
   *
   * @see [Angular Material Tooltip](https://material.angular.io/components/tooltip/overview)
   */
  @Input()
  set tooltipPosition(position: TooltipPosition | undefined) {
    this._inputTooltipPosition.set(position);
  }
  get tooltipPosition() {
    return this._inputTooltipPosition();
  }

  /**
   * Emits an event when the button is clicked.
   *
   * This serves as an **override** of the native click behavior, allowing
   * additional logic (e.g., dialogs, clipboard actions, navigation) before
   * executing the button's primary action.
   */
  @Output() buttonClick = new EventEmitter<MouseEvent>();

  /**
   * Stores any currently open dialogs.
   *
   * This is used to **manage and track dialogs** triggered by the button.
   */
  private dialog?: MatDialogRef<any>;

  /**
   * Injects all required services for button behavior and UI interactions.
   */
  constructor(
    private readonly appService: AppService,
    private readonly dialogService: DialogService,
    private readonly loggerService: LoggerService,
    private readonly snackbarService: SnackbarService,
    private readonly clipboard: Clipboard,
    private readonly buttonService: ButtonService,
    public readonly buttonRepository: ButtonRepository,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  ////////////////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Angular lifecycle hook that runs when the component is destroyed.
   *
   * This method:
   * - **Unsubscribes from any active subscriptions** to prevent memory leaks.
   * - **Closes any open dialogs** connected to this button before the component is removed.
   */
  ngOnDestroy() {
    // kill any dialog
    this.dialog?.close(false);
  }

  ////////////////////////////////////////////////////////////////////////////////
  // TEMPLATE EVENTS
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Intercepts button clicks and routes them based on the button's configuration.
   *
   * Handles dialog prompts, features (e.g. copy, print), navigation, and disabled states
   * before executing the final action.
   *
   * @param event - Mouse event from the button click.
   */
  onClick(event: MouseEvent) {
    // register the input
    this.appService.registerFirstInteraction();

    // if the button is disabled, then do nothing
    if (this.disabledState()) {
      this.loggerService.info(`[Button] - The button is disabled. No interaction will occur.`);
      return;
    }

    // if the button is processing, then do nothing
    if (this._processing()) {
      this.loggerService.info(`[Button] - The button is processing. No interaction will occur.`);
      return;
    }

    // if the type is a link, then process the link
    if (this.typeState() === 'link') {
      this.processLink();
      // otherwise it's a button
    } else {
      // if the button is associated with a dialog, then process the dialog
      if (this._hasDialog()) {
        this.processDialog(event);
        // if the button is associated with a feature, then process the feature
      } else if (this._hasFeature()) {
        if (this._hasClickToCloseFeature()) {
          this.processClickToCloseFeature();
        }

        if (this._hasCopyToClipboardFeature()) {
          this.processCopyToClipboardFeature(event);
        }

        if (this._hasPrintFeature()) {
          this.processPrintFeature();
        }
      } else {
        // otherwise process the button click
        this.processButton(event);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////
  // LINKING
  /////////////////////////////////////////////////////////////////////////////////////

  /**
   * Handles navigation for buttons of type `"link"`.
   *
   * - Prevents default click behavior.
   * - Retrieves the link from the repository.
   * - Navigates via the app service.
   *
   * @param event - Mouse event from the button click.
   */
  private processLink() {
    if (!this._link()) {
      return;
    }

    this.appService.openLink(this._link());
  }

  /////////////////////////////////////////////////////////////////////////////////////
  // DIALOG
  /////////////////////////////////////////////////////////////////////////////////////

  /**
   * Handles dialog interaction for buttons with a configured dialog.
   *
   * - Opens the dialog and waits for the result.
   * - Executes the button action only if the dialog is confirmed (`true`).
   *
   * @param event - Mouse event from the button click.
   */
  private processDialog(event: MouseEvent) {
    this._processing.set(true);

    this.dialog = this.dialogService.openDialog(this._dialog());
    this.dialog.afterClosed().subscribe((result) => {
      this._processing.set(false);
      this.dialog = undefined;
      if (result) {
        this.processButton(event);
      }
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////
  // FEATURES
  /////////////////////////////////////////////////////////////////////////////////////

  /**
   * Registers a close event for buttons with the Click-to-Close feature.
   *
   * @param event - Mouse event from the button click.
   */
  private processClickToCloseFeature() {
    this._processing.set(true);
    this.appService.registerClose();
  }

  /**
   * Handles the Copy-to-Clipboard feature for applicable buttons.
   *
   * - Copies configured data to the clipboard with retry logic.
   * - Optionally shows a snackbar notification.
   *
   * @param event - Mouse event from the button click.
   */
  private processCopyToClipboardFeature(event: MouseEvent) {
    // prevent any defaults
    event.preventDefault();
    event.stopImmediatePropagation();

    // copy to clipboard logic will optionally show a complete snackbar
    const pending = this.clipboard.beginCopy(this._copyToClipboard().data);
    let remainingAttempts = 5;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        document.defaultView.setTimeout(attempt);
      } else {
        pending.destroy();
        if (this._copyToClipboard().snackbar) {
          this.snackbarService.open(this._copyToClipboard().snackbar);
        }
      }
    };
    attempt();
  }

  /**
   * Triggers the browser's print dialog for buttons with the print feature.
   */
  private processPrintFeature() {
    this.document?.defaultView?.print();
  }

  /////////////////////////////////////////////////////////////////////////////////////
  // CLICK
  /////////////////////////////////////////////////////////////////////////////////////

  /**
   * Emits the `buttonClick` event for standard button interactions.
   *
   * @param event - Mouse event from the button click.
   */
  private processButton(event: MouseEvent) {
    this.buttonClick.emit(event);
  }
}
