import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { mediaStateAnimation } from '../media.animations';
import { MediaRepository } from '../media.repository';

/**
 * The Media UI.
 *
 * It belongs to the {@link CoreModule}.
 *
 * It is not standalone and belongs within the {@link MediaComponent}.
 */
@Component({
  selector: 'app-media-ui',
  templateUrl: './media-ui.component.html',
  styleUrls: ['./media-ui.component.scss'],
  animations: [mediaStateAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FlexModule, MatIcon, AsyncPipe],
})
export class MediaUIComponent {
  /**
   * Constructor.
   *
   * @param mediaRepository - a reference to the media query.
   */
  constructor(public readonly mediaRepository: MediaRepository) {}
}
