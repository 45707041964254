/* eslint-disable @typescript-eslint/no-explicit-any */

import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { Subscription, tap } from 'rxjs';
import { ICoreContainer } from 'src/app/api/modules/core/dynamic/ICoreContainer';
import { createDigitaServiceError } from 'src/app/app-error';
import { DynamicContentDirective } from 'src/app/modules/shared/directives/dynamic-content/dynamic-content.directive';
import { ContainerFactoryArray } from './container.factory';
import { ContainerRepository } from './container.repository';
import { ContainerService } from './container.service';

/**
 * Angular component responsible for rendering and managing a dynamic container
 * which serves as a placeholder for various DigitaService Dynamic Components.
 * It relies on an input configuration to determine the components to be dynamically
 * rendered within the container. The component uses OnPush change detection strategy
 * for performance optimization, and it provides its own instances of ContainerQuery,
 * ContainerStore, and ContainerService.
 *
 * @template Configuration - Extends the ICoreContainer interface to type the configuration input.
 *
 * See {@link ICoreContainer} for configuration structure.
 */
@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  providers: [ContainerService, ContainerRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FlexModule, DynamicContentDirective, AsyncPipe],
})
export class ContainerComponent<Configuration extends ICoreContainer = ICoreContainer> implements OnDestroy, AfterViewInit {
  /**
   * ViewChild directive used to dynamically inject content into the view.
   */
  @ViewChild(DynamicContentDirective, { static: false }) contentContainer: DynamicContentDirective;

  /**
   * Input setter for the component configuration. Initializes the container service
   * with the provided configuration upon setting. The getter returns the current configuration.
   *
   * @param configuration - An instance of Configuration, adhering to ICoreContainer.
   */
  private _config: Configuration;
  @Input()
  set config(configuration: Configuration) {
    this._config = configuration;
    this.containerService.initialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * Subscription collection for managing the lifecycle of observable subscriptions.
   */
  protected subscriptions = new Subscription();

  /**
   * A reference to the dynamically created components.
   */
  protected dynamicComponentRefs: ComponentRef<any>[] = [];

  /**
   * Initializes the component with the required services and change detector reference.
   *
   * @param containerRepository - Service for querying container state.
   * @param containerService - Service for container state management.
   * @param cd - Reference to the change detector service for manual change detection triggering.
   */
  constructor(
    public readonly containerRepository: ContainerRepository,
    protected readonly containerService: ContainerService,
    protected readonly cd: ChangeDetectorRef,
  ) {}

  /**
   * Lifecycle hook that is called after Angular has fully initialized the view.
   * Subscribes to container configuration updates and processes the component array
   * to dynamically create and render components.
   */
  ngAfterViewInit() {
    // Subscription to the container configuration
    const containerConfigurationSubscription = this.containerRepository.containerConfig$
      .pipe(
        tap((config) => {
          const componentArray = config.componentArray;

          if (componentArray && Array.isArray(componentArray) && componentArray.length > 0) {
            this.dynamicComponentRefs = this.createComponentsFromArray(componentArray);
          } else {
            throw createDigitaServiceError(
              'Container',
              'initialize',
              'Containers must be configured with a "componentArray" property',
              'config',
            );
          }
        }),
      )
      .subscribe(() => {
        this.cd.detectChanges();
      });
    this.subscriptions.add(containerConfigurationSubscription);
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * Unsubscribes from all subscriptions to prevent memory leaks.
   */
  ngOnDestroy() {
    // destroy the dynamic components
    this.dynamicComponentRefs.forEach((item) => {
      item.destroy();
    });

    // clear the array
    this.dynamicComponentRefs = [];

    // unsubscribe from all subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * Creates and renders dynamic components based on the provided component array.
   * Can be overridden by subclasses to customize the creation process.
   *
   * @param componentArray - Array of components to create, based on the Configuration['componentArray'] type.
   */
  protected createComponentsFromArray(componentArray: Configuration['componentArray']): ComponentRef<any>[] {
    return ContainerFactoryArray(this.contentContainer.viewContainerRef, componentArray);
  }
}
