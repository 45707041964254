/**
 * Defines a color palette for Material Design themes.
 *
 * This type represents a mapping of material color tokens to their corresponding color values.
 * It is used for different theme variations.
 */
export type MaterialColorPalette = Record<keyof typeof MaterialColorTokenKeys, string>;

/**
 * Defines the JSON Schema for Material Color Tokens.
 *
 * This interface represents the different color palettes used in the Material Design System.
 * Each palette corresponds to a specific theme variant:
 * - `light`: Standard light theme.
 * - `dark`: Standard dark theme.
 * - `light-high-contrast`: High-contrast version of the light theme.
 * - `dark-high-contrast`: High-contrast version of the dark theme.
 *
 * These palettes ensure consistent theming across Material Design components.
 */
export interface MaterialColorPalettes {
  light: MaterialColorPalette;
  dark: MaterialColorPalette;
  'light-high-contrast': MaterialColorPalette;
  'dark-high-contrast': MaterialColorPalette;
}

/**
 * Defines all Material Color Tokens.
 *
 * This object serves as the authoritative source for Material Color Tokens in JavaScript.
 * It ensures consistency across the Material Design System and is used to generate
 * the corresponding TypeScript interface through utility methods.
 *
 * Each key represents a Material Design color token, which follows a standardized naming
 * convention for theming and styling purposes.
 *
 * **Important:** This is the only place where Material Color Tokens should be listed in JavaScript.
 */
export const MaterialColorTokenKeys = {
  '--mat-sys-primary': undefined,
  '--mat-sys-on-primary': undefined,
  '--mat-sys-primary-container': undefined,
  '--mat-sys-on-primary-container': undefined,
  '--mat-sys-inverse-primary': undefined,
  '--mat-sys-primary-fixed': undefined,
  '--mat-sys-primary-fixed-dim': undefined,
  '--mat-sys-on-primary-fixed': undefined,
  '--mat-sys-on-primary-fixed-variant': undefined,
  '--mat-sys-secondary': undefined,
  '--mat-sys-on-secondary': undefined,
  '--mat-sys-secondary-container': undefined,
  '--mat-sys-on-secondary-container': undefined,
  '--mat-sys-secondary-fixed': undefined,
  '--mat-sys-secondary-fixed-dim': undefined,
  '--mat-sys-on-secondary-fixed': undefined,
  '--mat-sys-on-secondary-fixed-variant': undefined,
  '--mat-sys-tertiary': undefined,
  '--mat-sys-on-tertiary': undefined,
  '--mat-sys-tertiary-container': undefined,
  '--mat-sys-on-tertiary-container': undefined,
  '--mat-sys-tertiary-fixed': undefined,
  '--mat-sys-tertiary-fixed-dim': undefined,
  '--mat-sys-on-tertiary-fixed': undefined,
  '--mat-sys-on-tertiary-fixed-variant': undefined,
  '--mat-sys-background': undefined,
  '--mat-sys-on-background': undefined,
  '--mat-sys-surface': undefined,
  '--mat-sys-surface-dim': undefined,
  '--mat-sys-surface-bright': undefined,
  '--mat-sys-surface-container-lowest': undefined,
  '--mat-sys-surface-container': undefined,
  '--mat-sys-surface-container-high': undefined,
  '--mat-sys-surface-container-highest': undefined,
  '--mat-sys-on-surface': undefined,
  '--mat-sys-shadow': undefined,
  '--mat-sys-scrim': undefined,
  '--mat-sys-surface-tint': undefined,
  '--mat-sys-inverse-surface': undefined,
  '--mat-sys-inverse-on-surface': undefined,
  '--mat-sys-outline': undefined,
  '--mat-sys-outline-variant': undefined,
  '--mat-sys-neutral10': undefined,
  '--mat-sys-error': undefined,
  '--mat-sys-on-error': undefined,
  '--mat-sys-error-container': undefined,
  '--mat-sys-on-error-container': undefined,
  '--mat-sys-surface-variant': undefined,
  '--mat-sys-on-surface-variant': undefined,
  '--mat-sys-neutral-variant20': undefined,
};

/**
 * Default Material Color Tokens used in Drimify.
 *
 * This object defines the standard color palettes for Drimify's Material Design system.
 * It includes predefined themes that ensure consistent color representation across
 * various UI components.
 *
 * Available themes:
 * - `light`: Standard light theme.
 * - `dark`: Standard dark theme.
 * - `light-high-contrast`: High-contrast version of the light theme.
 * - `dark-high-contrast`: High-contrast version of the dark theme.
 *
 * These tokens serve as the foundation for theming in Drimify and are structured
 * to align with Material Design guidelines.
 */
export const DefaultMaterialColorTokens: MaterialColorPalettes = {
  light: {
    '--mat-sys-primary': '#6b24f9',
    '--mat-sys-on-primary': '#ffffff',
    '--mat-sys-primary-container': '#e8ddff',
    '--mat-sys-on-primary-container': '#21005e',
    '--mat-sys-inverse-primary': '#cebdff',
    '--mat-sys-primary-fixed': '#e8ddff',
    '--mat-sys-primary-fixed-dim': '#cebdff',
    '--mat-sys-on-primary-fixed': '#21005e',
    '--mat-sys-on-primary-fixed-variant': '#5200ce',
    '--mat-sys-secondary': '#006a66',
    '--mat-sys-on-secondary': '#ffffff',
    '--mat-sys-secondary-container': '#4ffaf2',
    '--mat-sys-on-secondary-container': '#00201f',
    '--mat-sys-secondary-fixed': '#4ffaf2',
    '--mat-sys-secondary-fixed-dim': '#15ddd6',
    '--mat-sys-on-secondary-fixed': '#00201f',
    '--mat-sys-on-secondary-fixed-variant': '#00504d',
    '--mat-sys-tertiary': '#ba0c4d',
    '--mat-sys-on-tertiary': '#ffffff',
    '--mat-sys-tertiary-container': '#ffd9de',
    '--mat-sys-on-tertiary-container': '#400015',
    '--mat-sys-tertiary-fixed': '#ffd9de',
    '--mat-sys-tertiary-fixed-dim': '#ffb2be',
    '--mat-sys-on-tertiary-fixed': '#400015',
    '--mat-sys-on-tertiary-fixed-variant': '#900039',
    '--mat-sys-background': '#f8f9ff',
    '--mat-sys-on-background': '#101c2b',
    '--mat-sys-surface': '#f8f9ff',
    '--mat-sys-surface-dim': '#cedbef',
    '--mat-sys-surface-bright': '#f8f9ff',
    '--mat-sys-surface-container-lowest': '#ffffff',
    '--mat-sys-surface-container': '#e5eeff',
    '--mat-sys-surface-container-high': '#dde9fe',
    '--mat-sys-surface-container-highest': '#d7e3f8',
    '--mat-sys-on-surface': '#101c2b',
    '--mat-sys-shadow': '#000000',
    '--mat-sys-scrim': '#000000',
    '--mat-sys-surface-tint': '#6b24f9',
    '--mat-sys-inverse-surface': '#253141',
    '--mat-sys-inverse-on-surface': '#eaf1ff',
    '--mat-sys-outline': '#7a7488',
    '--mat-sys-outline-variant': '#cbc3d9',
    '--mat-sys-neutral10': '#101c2b',
    '--mat-sys-error': '#ba1a1a',
    '--mat-sys-on-error': '#ffffff',
    '--mat-sys-error-container': '#ffdad6',
    '--mat-sys-on-error-container': '#410002',
    '--mat-sys-surface-variant': '#e7dff6',
    '--mat-sys-on-surface-variant': '#494456',
    '--mat-sys-neutral-variant20': '#322e3f',
  },
  dark: {
    '--mat-sys-primary': '#cebdff',
    '--mat-sys-on-primary': '#390094',
    '--mat-sys-primary-container': '#5200ce',
    '--mat-sys-on-primary-container': '#e8ddff',
    '--mat-sys-inverse-primary': '#6b24f9',
    '--mat-sys-primary-fixed': '#e8ddff',
    '--mat-sys-primary-fixed-dim': '#cebdff',
    '--mat-sys-on-primary-fixed': '#21005e',
    '--mat-sys-on-primary-fixed-variant': '#5200ce',
    '--mat-sys-secondary': '#15ddd6',
    '--mat-sys-on-secondary': '#003735',
    '--mat-sys-secondary-container': '#00504d',
    '--mat-sys-on-secondary-container': '#4ffaf2',
    '--mat-sys-secondary-fixed': '#4ffaf2',
    '--mat-sys-secondary-fixed-dim': '#15ddd6',
    '--mat-sys-on-secondary-fixed': '#00201f',
    '--mat-sys-on-secondary-fixed-variant': '#00504d',
    '--mat-sys-tertiary': '#ffb2be',
    '--mat-sys-on-tertiary': '#660026',
    '--mat-sys-tertiary-container': '#900039',
    '--mat-sys-on-tertiary-container': '#ffd9de',
    '--mat-sys-tertiary-fixed': '#ffd9de',
    '--mat-sys-tertiary-fixed-dim': '#ffb2be',
    '--mat-sys-on-tertiary-fixed': '#400015',
    '--mat-sys-on-tertiary-fixed-variant': '#900039',
    '--mat-sys-background': '#071422',
    '--mat-sys-on-background': '#d7e3f8',
    '--mat-sys-surface': '#071422',
    '--mat-sys-surface-dim': '#071422',
    '--mat-sys-surface-bright': '#2e3a4a',
    '--mat-sys-surface-container-lowest': '#030f1d',
    '--mat-sys-surface-container': '#14202f',
    '--mat-sys-surface-container-high': '#1f2b3a',
    '--mat-sys-surface-container-highest': '#2a3645',
    '--mat-sys-on-surface': '#d7e3f8',
    '--mat-sys-shadow': '#000000',
    '--mat-sys-scrim': '#000000',
    '--mat-sys-surface-tint': '#cebdff',
    '--mat-sys-inverse-surface': '#d7e3f8',
    '--mat-sys-inverse-on-surface': '#253141',
    '--mat-sys-outline': '#948da2',
    '--mat-sys-outline-variant': '#494456',
    '--mat-sys-neutral10': '#101c2b',
    '--mat-sys-error': '#ffb4ab',
    '--mat-sys-on-error': '#690005',
    '--mat-sys-error-container': '#93000a',
    '--mat-sys-on-error-container': '#ffdad6',
    '--mat-sys-surface-variant': '#494456',
    '--mat-sys-on-surface-variant': '#cbc3d9',
    '--mat-sys-neutral-variant20': '#322e3f',
  },
  'light-high-contrast': {
    '--mat-sys-primary': '#340089',
    '--mat-sys-on-primary': '#ffffff',
    '--mat-sys-primary-container': '#5400d4',
    '--mat-sys-on-primary-container': '#ffffff',
    '--mat-sys-inverse-primary': '#cebdff',
    '--mat-sys-primary-fixed': '#5400d4',
    '--mat-sys-primary-fixed-dim': '#3b009a',
    '--mat-sys-on-primary-fixed': '#ffffff',
    '--mat-sys-on-primary-fixed-variant': '#ffffff',
    '--mat-sys-secondary': '#003230',
    '--mat-sys-on-secondary': '#ffffff',
    '--mat-sys-secondary-container': '#00524f',
    '--mat-sys-on-secondary-container': '#ffffff',
    '--mat-sys-secondary-fixed': '#00524f',
    '--mat-sys-secondary-fixed-dim': '#003a37',
    '--mat-sys-on-secondary-fixed': '#ffffff',
    '--mat-sys-on-secondary-fixed-variant': '#ffffff',
    '--mat-sys-tertiary': '#5e0022',
    '--mat-sys-on-tertiary': '#ffffff',
    '--mat-sys-tertiary-container': '#95003b',
    '--mat-sys-on-tertiary-container': '#ffffff',
    '--mat-sys-tertiary-fixed': '#95003b',
    '--mat-sys-tertiary-fixed-dim': '#6b0028',
    '--mat-sys-on-tertiary-fixed': '#ffffff',
    '--mat-sys-on-tertiary-fixed-variant': '#ffffff',
    '--mat-sys-background': '#f8f9ff',
    '--mat-sys-on-background': '#101c2b',
    '--mat-sys-surface': '#f8f9ff',
    '--mat-sys-surface-dim': '#adbacd',
    '--mat-sys-surface-bright': '#f8f9ff',
    '--mat-sys-surface-container-lowest': '#ffffff',
    '--mat-sys-surface-container': '#d7e3f8',
    '--mat-sys-surface-container-high': '#c9d5e9',
    '--mat-sys-surface-container-highest': '#bbc7db',
    '--mat-sys-on-surface': '#000000',
    '--mat-sys-shadow': '#000000',
    '--mat-sys-scrim': '#000000',
    '--mat-sys-surface-tint': '#6b24f9',
    '--mat-sys-inverse-surface': '#253141',
    '--mat-sys-inverse-on-surface': '#ffffff',
    '--mat-sys-outline': '#2e2a3b',
    '--mat-sys-outline-variant': '#4c4659',
    '--mat-sys-neutral10': '#101c2b',
    '--mat-sys-error': '#600004',
    '--mat-sys-on-error': '#ffffff',
    '--mat-sys-error-container': '#98000a',
    '--mat-sys-on-error-container': '#ffffff',
    '--mat-sys-surface-variant': '#e7dff6',
    '--mat-sys-on-surface-variant': '#000000',
    '--mat-sys-neutral-variant20': '#322e3f',
  },
  'dark-high-contrast': {
    '--mat-sys-primary': '#f5edff',
    '--mat-sys-on-primary': '#000000',
    '--mat-sys-primary-container': '#cbb8ff',
    '--mat-sys-on-primary-container': '#0e0033',
    '--mat-sys-inverse-primary': '#5300d1',
    '--mat-sys-primary-fixed': '#e8ddff',
    '--mat-sys-primary-fixed-dim': '#cebdff',
    '--mat-sys-on-primary-fixed': '#000000',
    '--mat-sys-on-primary-fixed-variant': '#150043',
    '--mat-sys-secondary': '#abfff9',
    '--mat-sys-on-secondary': '#000000',
    '--mat-sys-secondary-container': '#00d9d2',
    '--mat-sys-on-secondary-container': '#000e0d',
    '--mat-sys-secondary-fixed': '#4ffaf2',
    '--mat-sys-secondary-fixed-dim': '#15ddd6',
    '--mat-sys-on-secondary-fixed': '#000000',
    '--mat-sys-on-secondary-fixed-variant': '#001413',
    '--mat-sys-tertiary': '#ffebed',
    '--mat-sys-on-tertiary': '#000000',
    '--mat-sys-tertiary-container': '#ffacba',
    '--mat-sys-on-tertiary-container': '#210007',
    '--mat-sys-tertiary-fixed': '#ffd9de',
    '--mat-sys-tertiary-fixed-dim': '#ffb2be',
    '--mat-sys-on-tertiary-fixed': '#000000',
    '--mat-sys-on-tertiary-fixed-variant': '#2c000c',
    '--mat-sys-background': '#071422',
    '--mat-sys-on-background': '#d7e3f8',
    '--mat-sys-surface': '#071422',
    '--mat-sys-surface-dim': '#071422',
    '--mat-sys-surface-bright': '#455162',
    '--mat-sys-surface-container-lowest': '#000000',
    '--mat-sys-surface-container': '#253141',
    '--mat-sys-surface-container-high': '#303c4c',
    '--mat-sys-surface-container-highest': '#3c4858',
    '--mat-sys-on-surface': '#ffffff',
    '--mat-sys-shadow': '#000000',
    '--mat-sys-scrim': '#000000',
    '--mat-sys-surface-tint': '#cebdff',
    '--mat-sys-inverse-surface': '#d7e3f8',
    '--mat-sys-inverse-on-surface': '#000000',
    '--mat-sys-outline': '#f5edff',
    '--mat-sys-outline-variant': '#c7bfd5',
    '--mat-sys-neutral10': '#101c2b',
    '--mat-sys-error': '#ffece9',
    '--mat-sys-on-error': '#000000',
    '--mat-sys-error-container': '#ffaea4',
    '--mat-sys-on-error-container': '#220001',
    '--mat-sys-surface-variant': '#494456',
    '--mat-sys-on-surface-variant': '#ffffff',
    '--mat-sys-neutral-variant20': '#322e3f',
  },
};

/**
 * Generates system tokens from the provided Material theme.
 *
 * This function takes an optional `MaterialColorTokens` object and returns a set
 * of system tokens based on the provided theme. If no theme is provided, it returns `undefined`.
 *
 * @param theme - The theme containing Material Color Tokens.
 *
 * @returns The generated system tokens or `undefined` if no theme is provided.
 */
export const GenerateMaterialColorSystemTokens = (theme?: MaterialColorTokens) => {
  if (!theme) {
    return undefined;
  }

  return {
    ...theme,
  };
};

/**
 * Interface representing the generated Material Color Tokens.
 *
 * This interface extends `MaterialColorPalettes`, making all properties optional.
 * It allows flexibility in defining color tokens while maintaining compatibility
 * with Material Design theming.
 */
export interface MaterialColorTokens extends Partial<MaterialColorPalettes> {}
