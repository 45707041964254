import { CdkScrollable } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { IDialogMediaLock } from 'src/app/api/modules/core/components/dialog/IDialogMediaLock';
import { MediaLockableComponent } from '../../components/static/media-lockable/media-lockable.component';
import { ButtonComponent } from '../../dynamic/components/button/button.component';
import { dialogMediaLockButtonAnimation } from './dialog-media-lock.animations';
import { DialogMediaLockRepository } from './dialog-media-lock.repository';
import { DialogMediaLockService } from './dialog-media-lock.service';

/**
 * Dialog Media Lock is a special kind of Dialog used to contain a {@link MediaLockable} component.
 *
 * They have a title, a MediaLockable, and a button.
 *
 * See {@link IDialogMediaLock}
 */
@Component({
  selector: 'app-dialog-media-lock',
  templateUrl: './dialog-media-lock.component.html',
  styleUrls: ['./dialog-media-lock.component.scss'],
  providers: [DialogMediaLockService, DialogMediaLockRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [dialogMediaLockButtonAnimation],
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MediaLockableComponent,
    MatDialogActions,
    FlexModule,
    // There is a circular dependency here, so we need to use forwardRef
    forwardRef(() => ButtonComponent),
    AsyncPipe,
  ],
})
export class DialogMediaLockComponent {
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // CONFIG
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The Configuration
   */
  private _config?: IDialogMediaLock;
  @Input()
  set config(configuration: IDialogMediaLock) {
    this._config = configuration;
    this.dialogMediaLockService.applyConfiguration(configuration);
  }
  get config(): IDialogMediaLock {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(
    private dialogRef: MatDialogRef<DialogMediaLockComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) data: IDialogMediaLock,
    public readonly dialogMediaLockRepository: DialogMediaLockRepository,
    private readonly dialogMediaLockService: DialogMediaLockService,
  ) {
    this.config = data;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Occurs from the Media Lock when the Media is Unlocked.
   */
  mediaLockUnlocked() {
    this.dialogMediaLockService.applyUnlock();
  }

  /**
   * Occurs from the Media Lock when the Media has experienced an error.
   */
  mediaLockError() {
    this.dialogMediaLockService.applyError();
  }

  /**
   * Occurs when the user clicks on the optional negative button.
   */
  onNegativeClick() {
    this.dialogRef.close(false);
  }

  /**
   * Occurs when the user clicks on the optional positive button.
   */
  onPositiveClick() {
    // Not used in this component.
    // this.dialogRef.close(true);
  }
}
