/**
 * All Material Typography Tokens.
 *
 * This is the only place where you should list Material Typography Tokens in Javascript.
 *
 * Utility methods generate the Typescript Interface from this list.
 */
export const MaterialTypographyTokenKeys = {
  '--mat-sys-display-large': undefined,
  '--mat-sys-display-large-font': undefined,
  '--mat-sys-display-large-line-height': undefined,
  '--mat-sys-display-large-size': undefined,
  '--mat-sys-display-large-tracking': undefined,
  '--mat-sys-display-large-weight': undefined,
  '--mat-sys-display-medium': undefined,
  '--mat-sys-display-medium-font': undefined,
  '--mat-sys-display-medium-line-height': undefined,
  '--mat-sys-display-medium-size': undefined,
  '--mat-sys-display-medium-tracking': undefined,
  '--mat-sys-display-medium-weight': undefined,
  '--mat-sys-display-small': undefined,
  '--mat-sys-display-small-font': undefined,
  '--mat-sys-display-small-line-height': undefined,
  '--mat-sys-display-small-size': undefined,
  '--mat-sys-display-small-tracking': undefined,
  '--mat-sys-display-small-weight': undefined,
  '--mat-sys-headline-large': undefined,
  '--mat-sys-headline-large-font': undefined,
  '--mat-sys-headline-large-line-height': undefined,
  '--mat-sys-headline-large-size': undefined,
  '--mat-sys-headline-large-tracking': undefined,
  '--mat-sys-headline-large-weight': undefined,
  '--mat-sys-headline-medium': undefined,
  '--mat-sys-headline-medium-font': undefined,
  '--mat-sys-headline-medium-line-height': undefined,
  '--mat-sys-headline-medium-size': undefined,
  '--mat-sys-headline-medium-tracking': undefined,
  '--mat-sys-headline-medium-weight': undefined,
  '--mat-sys-headline-small': undefined,
  '--mat-sys-headline-small-font': undefined,
  '--mat-sys-headline-small-line-height': undefined,
  '--mat-sys-headline-small-size': undefined,
  '--mat-sys-headline-small-tracking': undefined,
  '--mat-sys-headline-small-weight': undefined,
  '--mat-sys-title-large': undefined,
  '--mat-sys-title-large-font': undefined,
  '--mat-sys-title-large-line-height': undefined,
  '--mat-sys-title-large-size': undefined,
  '--mat-sys-title-large-tracking': undefined,
  '--mat-sys-title-large-weight': undefined,
  '--mat-sys-title-medium': undefined,
  '--mat-sys-title-medium-font': undefined,
  '--mat-sys-title-medium-line-height': undefined,
  '--mat-sys-title-medium-size': undefined,
  '--mat-sys-title-medium-tracking': undefined,
  '--mat-sys-title-medium-weight': undefined,
  '--mat-sys-title-small': undefined,
  '--mat-sys-title-small-font': undefined,
  '--mat-sys-title-small-line-height': undefined,
  '--mat-sys-title-small-size': undefined,
  '--mat-sys-title-small-tracking': undefined,
  '--mat-sys-title-small-weight': undefined,
  '--mat-sys-body-large': undefined,
  '--mat-sys-body-large-font': undefined,
  '--mat-sys-body-large-line-height': undefined,
  '--mat-sys-body-large-size': undefined,
  '--mat-sys-body-large-tracking': undefined,
  '--mat-sys-body-large-weight': undefined,
  '--mat-sys-body-medium': undefined,
  '--mat-sys-body-medium-font': undefined,
  '--mat-sys-body-medium-line-height': undefined,
  '--mat-sys-body-medium-size': undefined,
  '--mat-sys-body-medium-tracking': undefined,
  '--mat-sys-body-medium-weight': undefined,
  '--mat-sys-body-small': undefined,
  '--mat-sys-body-small-font': undefined,
  '--mat-sys-body-small-line-height': undefined,
  '--mat-sys-body-small-size': undefined,
  '--mat-sys-body-small-tracking': undefined,
  '--mat-sys-body-small-weight': undefined,
  '--mat-sys-label-large': undefined,
  '--mat-sys-label-large-font': undefined,
  '--mat-sys-label-large-line-height': undefined,
  '--mat-sys-label-large-size': undefined,
  '--mat-sys-label-large-tracking': undefined,
  '--mat-sys-label-large-weight': undefined,
  '--mat-sys-label-large-weight-prominent': undefined,
  '--mat-sys-label-medium': undefined,
  '--mat-sys-label-medium-font': undefined,
  '--mat-sys-label-medium-line-height': undefined,
  '--mat-sys-label-medium-size': undefined,
  '--mat-sys-label-medium-tracking': undefined,
  '--mat-sys-label-medium-weight': undefined,
  '--mat-sys-label-medium-weight-prominent': undefined,
  '--mat-sys-label-small': undefined,
  '--mat-sys-label-small-font': undefined,
  '--mat-sys-label-small-line-height': undefined,
  '--mat-sys-label-small-size': undefined,
  '--mat-sys-label-small-tracking': undefined,
  '--mat-sys-label-small-weight': undefined,
} as const;

/**
 * These are the shorthand keys for the Material Typography Tokens.
 *
 * They cannot be set through the JSON API.
 */
export const MaterialTypographyTokenKeysShorthand = {
  '--mat-sys-display-large': undefined,
  '--mat-sys-display-medium': undefined,
  '--mat-sys-display-small': undefined,
  '--mat-sys-headline-large': undefined,
  '--mat-sys-headline-medium': undefined,
  '--mat-sys-headline-small': undefined,
  '--mat-sys-title-large': undefined,
  '--mat-sys-title-medium': undefined,
  '--mat-sys-title-small': undefined,
  '--mat-sys-body-large': undefined,
  '--mat-sys-body-medium': undefined,
  '--mat-sys-body-small': undefined,
  '--mat-sys-label-large': undefined,
  '--mat-sys-label-medium': undefined,
  '--mat-sys-label-small': undefined,
} as const;

////////////////////////////////////////////////////////////////////////////////////////
// DO NOT MODIFY BELOW THIS LINE
////////////////////////////////////////////////////////////////////////////////////////

/**
 * Generates System Tokens from the provided Typography.
 */
export const GenerateMaterialTypographySystemTokens = (typography?: MaterialTypographyTokens) => {
  return typography;
};

/**
 * Helper typescript syntax for mapping keys from a constant to a type.
 */
type MappedInterface<T> = {
  [K in keyof T]?: string;
};

/**
 * Generated Interface for the Material Color Tokens.
 */
export interface MaterialTypographyTokens extends Partial<MappedInterface<typeof MaterialTypographyTokenKeys>> {}
