import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep } from 'lodash-es';
import { map } from 'rxjs';
import { IDynamicTheme } from 'src/app/api/modules/core/components/theme/IDynamicTheme';
import { DefaultDrimifyCustomTokens, GenerateDrimifyCustomTokens } from 'src/app/api/modules/core/components/theme/tokens/DrimifyTokens';
import {
  DefaultMaterialColorTokens,
  GenerateMaterialColorSystemTokens,
  MaterialColorPalette,
} from 'src/app/api/modules/core/components/theme/tokens/MaterialColorTokens';
import { GenerateMaterialTypographySystemTokens } from 'src/app/api/modules/core/components/theme/tokens/MaterialTypographyTokens';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';

/**
 * The Default State
 */
function initialState(): IDynamicTheme {
  return {
    darkMode: false,
    highContrastMode: false,
    colors: DefaultMaterialColorTokens,
    typography: undefined,
    drimify: DefaultDrimifyCustomTokens,
  };
}

/**
 * The Store used for the Dynamic Theme.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable({
  providedIn: 'root',
})
export class DynamicThemeRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `dynamic-theme`,
    },
    withProps<IDynamicTheme>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Configures the Theme State.
   */
  applyConfiguration(configuration?: IDynamicTheme) {
    const { darkMode, highContrastMode, colors, typography, drimify } = this.store.getValue();

    let newDarkMode = darkMode;
    if (configuration?.darkMode === true || configuration?.darkMode === false) {
      newDarkMode = configuration.darkMode;
    }

    let newHighContrastMode = highContrastMode;
    if (configuration?.highContrastMode === true || configuration?.highContrastMode === false) {
      newHighContrastMode = configuration.highContrastMode;
    }

    let newColors = colors;
    if (configuration?.colors) {
      newColors = cloneDeep(configuration.colors);
    }

    let newTypography = typography;
    if (configuration?.typography) {
      newTypography = cloneDeep(configuration.typography);
    }

    let newCustom = drimify;
    if (configuration?.drimify) {
      newCustom = cloneDeep(configuration.drimify);
    }

    const colorTokens = GenerateMaterialColorSystemTokens(newColors);
    const typographyTokens = GenerateMaterialTypographySystemTokens(newTypography);
    const drimifyTokens = GenerateDrimifyCustomTokens(newCustom);

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.darkMode = newDarkMode;
        state.highContrastMode = newHighContrastMode;
        state.colors = colorTokens;
        state.typography = typographyTokens;
        state.drimify = drimifyTokens;
      }),
    );
  }

  /**
   * Toggle Dark Mode.
   */
  toggleDarkMode() {
    const { darkMode } = this.store.getValue();

    this.store.update(
      ElfWrite((state) => {
        state.darkMode = !darkMode;
      }),
    );
  }

  /**
   * Toggle High Contrast Mode.
   */
  toggleHighContrastMode() {
    const { highContrastMode } = this.store.getValue();

    this.store.update(
      ElfWrite((state) => {
        state.highContrastMode = !highContrastMode;
      }),
    );
  }

  /**
   * Set Dark Mode.
   *
   * @param darkMode - true for Dark Mode, false for Light Mode
   */
  setDarkMode(darkMode: boolean) {
    this.store.update(
      ElfWrite((state) => {
        state.darkMode = darkMode;
      }),
    );
  }

  /**
   * Set High Contrast Mode.
   *
   * @param highContrastMode - true for High Contrast Mode, false for Normal Mode
   */
  setHighContrastMode(highContrastMode: boolean) {
    this.store.update(
      ElfWrite((state) => {
        state.highContrastMode = highContrastMode;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Is the Dark Mode enabled?
   */
  isDarkMode$ = this.store.pipe(select((state) => state.darkMode));

  /**
   * Is the High Contrast Mode enabled?
   */
  isHighContrastMode$ = this.store.pipe(select((state) => state.highContrastMode));

  /**
   * The Theme Colors
   */
  private _colors$ = this.store.pipe(select((state) => state.colors));

  /**
   * The selected Theme depends on the Dark Mode and High Contrast Mode.
   *
   * It will select the correct theme based on these modes along with the available themes.
   */
  activePalette$ = ElfCombineQueries([this.isDarkMode$, this.isHighContrastMode$, this._colors$]).pipe(
    map(([darkMode, highContrastMode, colors]) => {
      // the preferred mode based on user settings
      const preferredMode: 'light' | 'dark' | 'light-high-contrast' | 'dark-high-contrast' =
        darkMode && highContrastMode ? 'dark-high-contrast' : darkMode ? 'dark' : highContrastMode ? 'light-high-contrast' : 'light';

      // the fallback order for theme selection
      const modeFallbacks: ('dark-high-contrast' | 'dark' | 'light-high-contrast' | 'light')[] = [
        preferredMode,
        'dark-high-contrast',
        'dark',
        'light-high-contrast',
        'light',
      ];

      // selects the correct color variables
      const mode = modeFallbacks.find((m) => colors[m]) ?? 'light';

      // assigns the color based on the selected mode, ensuring fallback to 'light'
      const color: MaterialColorPalette = colors[mode] ?? colors['light'];

      // return the theme
      return color;
    }),
  );

  /**
   * The Theme Typography
   */
  private _typography$ = this.store.pipe(select((state) => state.typography));

  /**
   * The Theme Drimify
   */
  private _drimify$ = this.store.pipe(select((state) => state.drimify));

  /**
   * The Data for Themes
   */
  theme$ = ElfCombineQueries([this.isDarkMode$, this.isHighContrastMode$, this.activePalette$, this._typography$, this._drimify$]).pipe(
    map(([darkMode, highContrastMode, activePalette, typography, drimify]) => {
      return { darkMode, highContrastMode, activePalette, typography, drimify };
    }),
  );
}
