import { AsyncPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { EMPTY, Subscription, catchError, from, fromEvent, take } from 'rxjs';
import { createDigitaServiceError } from 'src/app/app-error';
import { MediaRepository } from '../media.repository';
import { MediaService } from '../media.service';
import { MediaNativeVideoModel } from './media-native-video.model';

/**
 * The Media Native Video Provider Component.
 *
 * It belongs to the {@link CoreModule}.
 *
 * It is not standalone and belongs within the {@link MediaComponent}.
 *
 * See {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video}
 */
@Component({
  selector: 'app-media-native-video',
  templateUrl: './media-native-video.component.html',
  styleUrls: ['./media-native-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe],
})
export class MediaNativeVideoComponent implements AfterViewInit, OnDestroy {
  /**
   * A reference to the media HTMLVideoElement.
   */
  @ViewChild('media')
  private mediaElement: ElementRef<HTMLVideoElement>;

  /**
   * A reference to the html video element from the media element ref.
   */
  player?: HTMLVideoElement;

  // various subscriptions used for teardown
  private configurationSubscription?: Subscription;
  private readySubscription?: Subscription;
  private playSubscription?: Subscription;
  private subscriptions?: Subscription;

  /**
   * Constructor.
   *
   * @param mediaRepository - a reference to the media query.
   * @param mediaService - a reference to the media service.
   */
  constructor(
    public readonly mediaRepository: MediaRepository,
    private readonly mediaService: MediaService,
  ) {}

  /////////////////////////////////////////////////////////////////////
  // LIFECYCLE
  /////////////////////////////////////////////////////////////////////

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // a reference to the player
    this.player = this.mediaElement.nativeElement;

    // listen out for when the audio configuration becomes available
    this.configurationSubscription = this.mediaRepository.videoSettings$.pipe(take(1)).subscribe((data) => {
      // this subscription is no longer needed
      this.configurationSubscription?.unsubscribe();

      // create the player.
      this.createPlayer(data);
    });
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    // unsubscribe from all subscriptions
    this.configurationSubscription?.unsubscribe();
    this.readySubscription?.unsubscribe();
    this.playSubscription?.unsubscribe();
    this.subscriptions?.unsubscribe();
  }

  /**
   * Create the player.
   *
   * @param options - the vimeo options
   */
  private createPlayer(options: MediaNativeVideoModel) {
    // cleanup any existing subscriptions
    this.subscriptions?.unsubscribe();
    this.subscriptions = new Subscription();

    this.readySubscription?.unsubscribe();
    this.playSubscription?.unsubscribe();

    // if there is no player element then that is a unexpected error
    if (!this.player) {
      throw createDigitaServiceError('MediaNativeVideoComponent', 'createPlayer', 'No player found', 'internal');
    }

    // set the top level attributes
    this.player.autoplay = options.autoplay;
    this.player.controls = options.controls;
    this.player.loop = options.loop;
    this.player.playsInline = options.playsinline;
    this.player.muted = options.muted;
    if (options.pip === false) {
      this.player.disablePictureInPicture = true;
    }
    if (options.poster) {
      this.player.poster = options.poster;
    }

    // the preload method determins when "ready" is called.
    if (options.preload) {
      // if we are preloading the entire source, then we will wait for video to fully download before
      // delcaring itself ready
      // See https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/canplaythrough_event
      this.readySubscription = fromEvent(this.player, 'canplaythrough').subscribe(() => {
        // check out the players state, if it's 3 or 4 then we can
        // consider it ready
        if (this.player?.readyState === 4) {
          // once ready we will find the total time of the video
          this.updateDuration();

          // inform the store that the video is ready
          this.mediaService.applyReady();

          // remove the subscription
          this.readySubscription?.unsubscribe();
        }
      });
    } else {
      // if we are not preloading the entire source, then we will try to play as soon as possible
      // before delcaring itself ready
      // see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/canplay_event
      this.readySubscription = fromEvent(this.player, 'canplay').subscribe(() => {
        // check out the players state, if it's 3 or 4 then we can
        // consider it ready
        if (this.player?.readyState === 3 || this.player?.readyState === 4) {
          // once ready we will find the total time of the video
          this.updateDuration();

          // inform the store that the video is ready
          this.mediaService.applyReady();

          // remove the subscription
          this.readySubscription?.unsubscribe();
        }
      });
    }

    // listen out for when the media has ended
    const ended = fromEvent(this.player, 'ended').subscribe(() => {
      this.videoEnded();
    });
    this.subscriptions.add(ended);

    // listen out for when the media has started playing
    const playing = fromEvent(this.player, 'play').subscribe(() => {
      this.videoPlaying();
    });
    this.subscriptions.add(playing);

    // listen out for when the media has paused
    const paused = fromEvent(this.player, 'pause').subscribe(() => {
      this.videoPaused();
    });
    this.subscriptions.add(paused);

    // listen out for time updates
    const timeUpdate = fromEvent(this.player, 'timeupdate').subscribe(() => {
      this.updateCurrentTime();
    });
    this.subscriptions.add(timeUpdate);

    // listen out for duration change events
    const duration = fromEvent(this.player, 'durationchange').subscribe(() => {
      this.updateDuration();
    });
    this.subscriptions.add(duration);

    // listen out for errors
    const error = fromEvent(this.player, 'error').subscribe(() => {
      this.onError();
    });
    this.subscriptions.add(error);

    // create integrations
    this.createPlayerIntegration();

    // figure out what source can be played
    let source: string | undefined = undefined;
    for (let i = 0; i < options.sources.length; i++) {
      if (!source) {
        const src = options.sources[i];
        if (this.player.canPlayType(src.type)) {
          source = src.src;
          break;
        }
      }
    }

    // finally play the source
    if (source) {
      this.player.src = source;
      this.player.load();
    } else {
      this.onError('No compatible source found for this browser.');
    }
  }

  /**
   * Integrates the player with the MediaService of the application.
   */
  private createPlayerIntegration() {
    // listen for play events.
    const play = this.mediaService.play$.subscribe(() => {
      this.play();
    });
    this.subscriptions.add(play);

    // listen for pause events.
    const pause = this.mediaService.pause$.subscribe(() => {
      this.pause();
    });
    this.subscriptions.add(pause);

    // seek to the current time
    const seekTo = this.mediaService.seekTo$.subscribe((data) => {
      if (this.player) {
        if (data) {
          this.player.currentTime = data.seconds;
          if (data.play) {
            this.play();
          } else {
            this.pause();
          }
        }
      }
    });
    this.subscriptions.add(seekTo);

    // mute the media
    const mute = this.mediaService.mute$.subscribe(() => {
      if (this.player) {
        this.player.muted = true;
      }
    });
    this.subscriptions.add(mute);

    // unMute the media
    const unMute = this.mediaService.unMute$.subscribe(() => {
      if (this.player) {
        this.player.muted = false;
      }
    });
    this.subscriptions.add(unMute);

    // set the volume
    const volume = this.mediaService.volume$.subscribe((volume) => {
      if (typeof volume === 'number' && volume >= 0 && volume <= 100) {
        if (this.player) {
          this.player.volume = volume / 100;
        }
      }
    });
    this.subscriptions.add(volume);
  }

  /**
   * The Video has ended.
   */
  private videoEnded() {
    if (this.player) {
      this.mediaService.applyCurrentTime(this.player.duration);
    }
    this.mediaService.applyEnded();
  }

  /**
   * Occurs when the video is playing.
   */
  private videoPlaying() {
    this.mediaService.applyPlaying();
  }

  /**
   * Occurs when the video is paused.
   */
  private videoPaused() {
    this.mediaService.applyPaused();
  }

  /////////////////////////////////////////////////////////////////////
  // UTILS
  /////////////////////////////////////////////////////////////////////

  /**
   * Retrieve the current time from the player and save it to the store.
   */
  private updateCurrentTime() {
    const currentTime = this.player?.currentTime || 0;
    this.mediaService.applyCurrentTime(Math.floor(currentTime));
  }

  /**
   * Retrieve the total duration from the player and save it to the store.
   */
  private updateDuration() {
    const duration = this.player?.duration || 0;
    this.mediaService.applyDuration(Math.floor(duration));
  }

  /**
   * Occurs when an error occurs.
   *
   * @param reason - The reason for the error.
   */
  private onError(reason?: string) {
    this.mediaService.applyError(reason);
  }

  /////////////////////////////////////////////////////////////////////
  // CONTROLS
  /////////////////////////////////////////////////////////////////////

  /**
   * Play the video.
   */
  play() {
    // cancel any in progress subscriptions
    this.playSubscription?.unsubscribe();

    // if the doesnt exist then abort
    if (!this.player) {
      return;
    }

    // play the video
    this.playSubscription = from(this.player.play())
      .pipe(
        take(1),
        catchError((event) => {
          console.error(event);
          // allow the error to fail silently
          return EMPTY;
        }),
      )
      .subscribe(() => {
        // unsubscribe from the subscription
        this.playSubscription?.unsubscribe();
      });
  }

  /**
   * Pause the video.
   */
  pause() {
    // cancel any in progress subscriptions
    this.playSubscription?.unsubscribe();

    // if the player exists then pause it
    this.player?.pause();
  }

  /////////////////////////////////////////////////////////////////////
  // TEMPLATE EVENTS
  /////////////////////////////////////////////////////////////////////

  /**
   * Occurs on pointer up from the touch div when a Vimeo has no controls.
   */
  controlZonePointerUp() {
    if (this.player) {
      if (this.player.paused) {
        this.play();
      } else {
        this.pause();
      }
    }
  }
}
