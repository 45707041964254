import { Injectable, OnDestroy } from '@angular/core';
import { MediaChange } from '@ngbracket/ngx-layout';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { filter, map, shareReplay } from 'rxjs';
import { IButton } from 'src/app/api/modules/core/dynamic/components/IButton';
import { IButtonContainer } from 'src/app/api/modules/core/dynamic/containers/IButtonContainer';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { ButtonContainerModel } from './button-container.model';

/**
 * The Default State
 */
function initialState(): ButtonContainerModel {
  return {
    configured: false,
    layout: 'column',
    layoutAlign: 'start stretch',
    mediaAlias: null,
    componentArray: [],
    buttonSizing: undefined,
    preferredButtonSizing: undefined,
    preferredLayout: undefined,
    buttonCount: 0,
  };
}

/**
 * The Store used for a {@link ButtonContainerComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class ButtonContainerRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `button-container-${uniqueId()}`,
    },
    withProps<ButtonContainerModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initiailize a button store
   */
  initialize(configuration?: IButtonContainer) {
    // if there is no configuration then that is an issue
    if (!configuration) {
      throw createDigitaServiceError('ButtonContainer', 'initialize', 'You must supply a configuration to a button container.', 'config');
    }

    // if there are no buttons for the button container than that is an issue
    if (!configuration.componentArray || configuration.componentArray.length === 0) {
      throw createDigitaServiceError(
        'ButtonContainer',
        'initialize',
        'You must supply atleast one button to a button container.',
        'config',
      );
    }

    // check that all objects in the component array have a selector of type "app-button" or that is an error
    for (let i = 0; i < configuration.componentArray.length; i++) {
      const component = configuration.componentArray[i];
      if (component.selector !== 'app-button') {
        throw createDigitaServiceError(
          'ButtonContainer',
          'initialize',
          'All components in the component array must be of `"selector": "app-button"`',
          'config',
        );
      }
    }

    // preferred layout
    const preferredLayout: 'column' | 'row' | 'row-reverse' | undefined = configuration.layout ?? undefined;

    // preferred button sizing
    const preferredButtonSizing: 'fixedSize' | 'fullSize' | undefined = configuration.buttonSizing ?? undefined;

    // component array
    const componentArray: IButton[] = configuration.componentArray ?? [];

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.preferredButtonSizing = preferredButtonSizing;
        state.preferredLayout = preferredLayout;
        state.componentArray = componentArray;
        state.buttonCount = configuration.componentArray.length;
      }),
    );

    // process
    this.processState();
  }

  /**
   * Initiailize a button store
   */
  mediaChange(change: MediaChange) {
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.mediaAlias = change.mqAlias;
      }),
    );

    // process
    this.processState();
  }

  /**
   * Takes the current configuration and uses that information to process the state
   */
  private processState() {
    const { preferredButtonSizing, preferredLayout, buttonCount, mediaAlias } = this.store.getValue();

    let layout: 'row' | 'row-reverse' | 'column' = 'column';
    let layoutAlign = 'space-around center';
    let buttonSizing: 'fixedSize' | 'fullSize' | undefined = undefined;
    let isStacked = true;

    if (mediaAlias) {
      // Try to use a row layout if:
      // - The preferred layout is a row-type
      // - There are fewer than 3 buttons
      // - The media alias is not 'xs' (small screens don't support row layouts)
      if ((preferredLayout === 'row' || preferredLayout === 'row-reverse') && buttonCount < 3 && mediaAlias !== 'xs') {
        layout = preferredLayout;
        layoutAlign = 'space-between center';
        buttonSizing = preferredButtonSizing;
        isStacked = false;
      }
      // If a valid row layout wasn't chosen and there's exactly one button,
      // use the preferred sizing (non-stacked)
      else if (buttonCount === 1) {
        buttonSizing = preferredButtonSizing;
        isStacked = false;
      }
    }

    // If buttons remain stacked, force the sizing to fixed.
    if (isStacked) {
      buttonSizing = 'fixedSize';
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.layout = layout;
        state.layoutAlign = layoutAlign;
        state.buttonSizing = buttonSizing;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Is the system configured?
   */
  private _configured$ = this.store.pipe(
    select((state) => state.configured),
    filter((configured) => configured),
    shareReplay(1),
  );

  /**
   * The layout of the container is determined at runtime.
   */
  private _layout$ = this.store.pipe(
    select((state) => state.layout),
    shareReplay(1),
  );

  /**
   * The layout align of the container is determined at runtime.
   */
  private _layoutAlign$ = this.store.pipe(
    select((state) => state.layoutAlign),
    shareReplay(1),
  );

  /**
   * What sizing mode is applied to the buttons?
   */
  private _buttonSizing$ = this.store.pipe(
    select((state) => state.buttonSizing),
    shareReplay(1),
  );

  /**
   * The Button Sizing Data.
   */
  buttonSizing$ = ElfCombineQueries([this._configured$, this._buttonSizing$]).pipe(
    filter(([configured]) => configured),
    map(([, buttonSizing]) => {
      const isFixedSize = buttonSizing === 'fixedSize';
      const isFullSize = buttonSizing === 'fullSize';

      return {
        isFixedSize,
        isFullSize,
      };
    }),
    shareReplay(1),
  );

  /**
   * Contains all layout data.
   */
  templateData$ = ElfCombineQueries([this._configured$, this._layout$, this._layoutAlign$]).pipe(
    filter(([configured]) => configured),
    map(([, layout, layoutAlign]) => {
      return {
        layout,
        layoutAlign,
      };
    }),
    shareReplay(1),
  );

  /**
   * The component array containing the buttons.
   */
  private _componentArray$ = this.store.pipe(select((state) => state.componentArray));

  /**
   * The component array.
   */
  componentArray$ = ElfCombineQueries([this._configured$, this._componentArray$]).pipe(
    filter(([configured]) => configured),
    map(([, componentArray]) => {
      return componentArray;
    }),
    shareReplay(1),
  );
}
