import { Injectable } from '@angular/core';
import { IButton } from 'src/app/api/modules/core/dynamic/components/IButton';
import { ButtonRepository } from './button.repository';

/**
 * The `ButtonService` is used to manage and apply configurations to the {@link ButtonComponent}.
 *
 * This service is responsible for handling various button-related configurations and passing them to
 * the `ButtonRepository`, which maintains the state of the button.
 */
@Injectable()
export class ButtonService {
  /**
   * Constructor that injects the `ButtonRepository` to manage button state.
   *
   * @param buttonRepository - The repository that stores and handles button state.
   */
  constructor(private readonly buttonRepository: ButtonRepository) {}

  /**
   * Initializes the button with the given configuration.
   *
   * This method applies the incoming configuration to the button's model.
   *
   * @param configuration - The button configuration to apply (optional).
   */
  applyInitialize(configuration?: IButton) {
    this.buttonRepository.applyInitialize(configuration);
  }
}
