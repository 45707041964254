import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDataboxHighScore } from 'src/app/api/modules/core/dynamic/databoxes/highscore/IDataboxHighScore';
import { DataboxHighScoreRepository } from './databox-highscore.repository';

@Component({
  selector: 'app-databox-highscore',
  templateUrl: './databox-highscore.component.html',
  styleUrls: ['./databox-highscore.component.scss'],
  providers: [DataboxHighScoreRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe],
})
export class DataboxHighScoreComponent {
  /**
   * The Configuration powering the component.
   */
  private _config?: IDataboxHighScore;
  @Input()
  set config(configuration: IDataboxHighScore) {
    this._config = configuration;
    this.databoxHighscoreRepository.applyInitialize(configuration);
  }
  get config() {
    return this._config;
  }

  /**
   * Constructor
   */
  constructor(public readonly databoxHighscoreRepository: DataboxHighScoreRepository) {}

  /**
   * Updates the component with the users score
   *
   * @param score - the score of the user
   */
  update(score?: number) {
    this.databoxHighscoreRepository.applyUpdate(score);
  }

  /**
   * Resets the DataBox to defaults.
   */
  reset() {
    this.databoxHighscoreRepository.applyReset();
  }
}
