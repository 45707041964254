import { LoggerService } from '@angular-ru/cdk/logger';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AsyncPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  DigitaServiceError,
  FRAME_EVENT_TYPES,
  Frame,
  IFrameEventComplete,
  IFrameEventCreateDialog,
  IFrameEventCreateEffect,
  IFrameEventCreateSnackbar,
  IFrameEventCreateText,
  IFrameEventDBAttemptsIconData,
  IFrameEventDBHighScoreData,
  IFrameEventDBLivesIconData,
  IFrameEventDBProgressionIconData,
  IFrameEventDBProgressionNumericData,
  IFrameEventDBScoreData,
  IFrameEventDBTimerData,
  IFrameEventError,
  IFrameEventReady,
  IFrameEventResize,
  IFrameReceiptCreateDialog,
  IFrameReceiptCreateSnackbar,
  IFrameReceiptCreateText,
  IMessage,
} from '@digitaservice/utils';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { IShellEffects } from 'src/app/api/modules/core/components/effects/IShellEffects';
import { IPlugin } from 'src/app/api/modules/core/dynamic/plugin/components/IPlugin';
import { createDigitaServiceError } from 'src/app/app-error';
import { ConvertPluginToDialog } from 'src/app/factories/converters/dialog.converter';
import { ConvertPluginToSnackbar } from 'src/app/factories/converters/snackbar.converter';
import { DialogService } from 'src/app/modules/core/services/dialog.service';
import { SnackbarService } from 'src/app/modules/core/services/snackbar.service';
import { AppRepository } from '../../../services/application/application.repository';
import { AppService } from '../../../services/application/application.service';
import { ShellEffectsService } from '../../../services/effects/shell-effects.service';
import { DataboxContainerComponent } from '../../databoxes/components/databox-container/databox-container.component';
import { PluginTextComponent } from '../plugin-text/plugin-text.component';
import { PluginRepository } from './plugin.repository';
import { PluginService } from './plugin.service';

@Component({
  selector: 'app-plugin',
  templateUrl: './plugin.component.html',
  styleUrls: ['./plugin.component.scss'],
  providers: [PluginService, PluginRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', [
      state('hidden', style({ opacity: 0 })),
      state('visible', style({ opacity: 1 })),
      transition('hidden => visible', animate('1000ms ease-in')),
    ]),
  ],
  imports: [FlexModule, MatProgressSpinner, DataboxContainerComponent, PluginTextComponent, AsyncPipe],
})
export class PluginComponent implements AfterViewInit, OnDestroy {
  /**
   * Holds a reference to the raw iframe element
   */
  @ViewChild('iframe') private iframeRef: ElementRef<HTMLIFrameElement>;

  /**
   * Holds a reference to the databox
   */
  @ViewChild('databox') private databox: DataboxContainerComponent;

  /**
   * Holds a reference to the text layer
   */
  @ViewChild('text') private textRenderer: PluginTextComponent;

  /**
   * Configures the IFrame of the component that we wish to display
   */
  @Input()
  set config(configuration: IPlugin) {
    this.pluginService.applyConfiguration(configuration);
  }

  /**
   * Contains any subscriptions for later removal.
   */
  private resizeSubscription?: Subscription;
  private interactionSubscription?: Subscription;
  private templataReadySubscription?: Subscription;
  private heartbeatSubscription?: Subscription;

  // subscriptions used for API calls.
  private onReadyAPISubscription?: Subscription;
  private onCompleteAPISubscription?: Subscription;
  private onErrorAPISubscription?: Subscription;
  private onEventAPISubscription?: Subscription;

  /**
   * The Frame Element wraps the provided IFrame Native Element
   *
   * Comes from DigitaService Utils.
   */
  private frame?: Frame;

  /**
   * The PluginComponent wraps an IFrame and Integrates with DigitaService Integration
   *
   * See [IPlugin Interface]{@link IPlugin}
   */
  constructor(
    private readonly appService: AppService,
    private readonly appRepository: AppRepository,
    private readonly dialogService: DialogService,
    private readonly snackbarService: SnackbarService,
    private readonly ngZone: NgZone,
    private readonly loggerService: LoggerService,
    public readonly pluginRepository: PluginRepository,
    public readonly pluginService: PluginService,
    private readonly effectService: ShellEffectsService,
  ) {}

  /////////////////////////////////////////////////////////////////////////
  // LIFE CYCLE
  /////////////////////////////////////////////////////////////////////////

  /**
   * Lifecycle
   */
  ngAfterViewInit() {
    // listen for window resizes and tell the iframe to report back its new dimensions
    this.resizeSubscription = this.appRepository.viewportSize$.subscribe(() => {
      this.frame?.resize();
    });

    // listen for system interaction enabled events to block input from the frame.
    this.interactionSubscription = this.pluginRepository.interactionEnabled$.subscribe((interactionEnabled) => {
      this.frame?.toggleInput(interactionEnabled);
    });

    // listen for when template data becomes available
    this.templataReadySubscription = this.pluginRepository.templateData$
      .pipe(
        // this only happens once
        take(1),
      )
      .subscribe((templateData) => {
        // create the frame holding the plugin
        this.createFrame(templateData.src);
      });

    // The heartbeat will periodically send scoring events to the server continually if configured.
    this.heartbeatSubscription = this.pluginRepository.heartbeat$
      .pipe(
        // with the heartbeat info.
        switchMap((data) => {
          // create a network request.
          return this.pluginService.requestOnHeartbeatAPI(data.heartbeatAPI, data.heartbeatScore);
        }),
      )
      .subscribe({
        // `next` callback is empty because we don't need to act on the emitted value.
        next: () => {
          // Do nothing as this is a fire-and-forget operation.
        },
        // `complete` callback is empty.
        complete: () => {
          // Do nothing here as well.
        },
        // `error` callback is empty to prevent global errors if a heartbeat fails.
        error: () => {
          // We ignore errors to avoid affecting the system globally.
        },
      });
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.destroyFrame();
    this.resizeSubscription?.unsubscribe();
    this.interactionSubscription?.unsubscribe();
    this.templataReadySubscription?.unsubscribe();
    this.heartbeatSubscription?.unsubscribe();
    this.onReadyAPISubscription?.unsubscribe();
    this.onCompleteAPISubscription?.unsubscribe();
    this.onErrorAPISubscription?.unsubscribe();
    this.onEventAPISubscription?.unsubscribe();
  }

  /////////////////////////////////////////////////////////////////////////
  // FRAME CREATION AND DESTRUCTION
  /////////////////////////////////////////////////////////////////////////

  /**
   * Create the Frame System.
   *
   * @param url - the url of the iframe.
   */
  private createFrame(url: string) {
    // log
    this.loggerService.log('[Plugin] load', url);

    // only `digitaservice-widget` is false, app plugins are always true.
    const forPlugin = true;

    // the native iframe element required by Frame
    const iframe = this.iframeRef.nativeElement;

    // finally create the frame
    this.frame = new Frame({
      forPlugin,
      iframe,
      url,
    });
    this.frame.onReady = (event) => {
      this.ngZone.run(() => this.onPluginReady(event));
    };
    this.frame.onComplete = (event) => {
      this.ngZone.run(() => this.onPluginComplete(event));
    };
    this.frame.onEvent = (event) => {
      this.ngZone.run(() => this.onPluginEvent(event));
    };
    this.frame.onError = (errorEvent) => {
      this.ngZone.run(() => this.onPluginError(errorEvent));
    };
    this.frame.onResize = (resizeEvent) => {
      this.ngZone.run(() => this.onPluginResize(resizeEvent));
    };
    this.frame.onScrollToTop = () => {
      // unused in this context
    };
    this.frame.load();

    // the frame should not be interactive.
    this.frame.toggleInput(false);
    this.pluginService.applyFrameInteractive(false);
  }

  /**
   * Destroy the Frame System.
   */
  private destroyFrame() {
    this.frame?.destroy();
  }

  ///////////////////////////////////////////////////////////////////////////
  // FRAME CALLBACKS
  ///////////////////////////////////////////////////////////////////////////

  /**
   * When a Plugin is `ready` we may be required to set this data to the server.
   *
   * @param event - the plugin event dispatched from the game
   */
  private onPluginReady(event: IFrameEventReady) {
    // log
    this.loggerService.log('[plugin]::onPluginReady', event);

    // the content is ready
    this.pluginService.applyReady();

    // a final resize call to ensure the sizing
    this.frame?.resize();

    // the iframe should take focus
    this.frame?.focus();

    // the iframe is now active
    this.pluginService.applyFrameInteractive(true);

    // fire and forget any onReadyAPI call.
    this.onReadyAPISubscription?.unsubscribe();

    // retrieve the onReadyAPI.
    this.onReadyAPISubscription = this.pluginRepository.onReadyAPI$
      .pipe(
        // we only need to do this once.
        take(1),
        // make a network request.
        switchMap((onReadyAPI) => {
          return this.pluginService.requestOnReadyAPI(onReadyAPI, event);
        }),
      )
      .subscribe();
  }

  /**
   * When a Plugin dispatches any other event that isn't `ready` `success` `error` or `complete`
   * then that event will be processed depending if it is a known system event (such as updating
   * databoxes or creating Dialogs). If the event is an unknown system event, it may be dispatched
   * to the onEventAPI should that be configured.
   *
   * @param event - the plugin event dispatched from the frame content.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onPluginEvent(event: IMessage<string, any>) {
    // check to see if the incoming event is custom or a system reserved type
    const systemEventTypes = Object.values(FRAME_EVENT_TYPES) as string[];
    if (systemEventTypes.indexOf(event.type) === -1) {
      // if the event type is unknown, then it is considered a custom event.
      this.processUnknownEvent(event);
    } else {
      // the incoming event is a known system event
      switch (event.type) {
        default:
          throw createDigitaServiceError(`Plugin`, `onPluginEvent`, `The event type dispatched by the frame is unknown.`, `internal`);

        // CREATION PROCESSING
        case FRAME_EVENT_TYPES.DIALOG:
          this.processFrameCreateDialog(event as IFrameEventCreateDialog);
          break;
        case FRAME_EVENT_TYPES.SNACKBAR:
          this.processFrameCreateSnackbar(event as IFrameEventCreateSnackbar);
          break;
        case FRAME_EVENT_TYPES.TEXT:
          this.processFrameCreateText(event as IFrameEventCreateText);
          break;
        case FRAME_EVENT_TYPES.EFFECT:
          this.processFrameCreateEffect(event as IFrameEventCreateEffect);
          break;

        // DATABOX PROCESSING
        case FRAME_EVENT_TYPES.DB_ATTEMPTS_ICON:
          this.processFrameAttemptsIcon(event.data as IFrameEventDBAttemptsIconData);
          break;
        case FRAME_EVENT_TYPES.DB_HIGHSCORE:
          this.processFrameHighScore(event.data as IFrameEventDBHighScoreData);
          break;
        case FRAME_EVENT_TYPES.DB_LIVES_ICON:
          this.processFrameLivesIcon(event.data as IFrameEventDBLivesIconData);
          break;
        case FRAME_EVENT_TYPES.DB_PROGRESS_ICON:
          this.processFrameProcessIcon(event.data as IFrameEventDBProgressionIconData);
          break;
        case FRAME_EVENT_TYPES.DB_PROGRESS_NUMERIC:
          this.processFrameProgressionNumeric(event.data as IFrameEventDBProgressionNumericData);
          break;
        case FRAME_EVENT_TYPES.DB_SCORE:
          this.processFrameScore(event.data as IFrameEventDBScoreData);
          break;
        case FRAME_EVENT_TYPES.DB_TIMER:
          this.processFrameTimer(event.data as IFrameEventDBTimerData);
          break;
      }
    }
  }

  /**
   * When a Plugin dispatches a `complete` event, it means the iframe has completed
   * and has provided any metrics about what happened. This object is sent to the
   * server for processing and should tell us where to go next.
   *
   * The `complete` request is guarenteed to make it through and will retry indefinitely.
   *
   * Note: The `onCompleteAPI` should not be used when using Plugins with a Dialog.
   *
   * @param event - the plugin event dispatched from the iframe
   */
  private onPluginComplete(event: IFrameEventComplete) {
    // log
    this.loggerService.log('[plugin]::onPluginComplete event', event);

    // disable mouse access on the iframe
    this.pluginService.applyFrameInteractive(false);

    // any existing api subscription should be cancelled.
    this.onCompleteAPISubscription?.unsubscribe();

    // any existing heartbeat should be canceled
    this.heartbeatSubscription?.unsubscribe();

    // retrieve the onCompleteAPI from the store.
    this.onCompleteAPISubscription = this.pluginRepository.onCompleteAPI$
      .pipe(
        // only once
        take(1),
        switchMap((onCompleteAPI) => {
          // if the onCompleteAPI is provided then make a request to the server.
          return this.pluginService.requestOnCompleteAPI(onCompleteAPI, event);
        }),
      )
      .subscribe((response) => {
        // with the server response.
        this.loggerService.log('[plugin]::onCompleteAPI success', response);

        // there should be a destination.
        if (response?.destination) {
          // navigate to it.
          this.loggerService.log('[Plugin]::onPluginComplete navigate to destination', response.destination);
          this.appService.openLink(response.destination);
        } else {
          // otherwise throw a new error if no destination is provided.
          throw new DigitaServiceError('[Plugin] - Responses from the onCompleteAPI should always provide a "destination" property.');
        }
      });
  }

  /**
   * When a Plugin dispatches an `error` event, it means the iframe has experienced
   * trouble that cannot be fixed. This results in a dead end for the application and
   * so the server is informed so that it may try to handle the outcome gracefully.
   *
   * The `error` request is guarenteed to make it through and will retry indefinitely.
   *
   * @param event - the plugin event dispatched from the iframe
   */
  private onPluginError(event: IFrameEventError) {
    // log
    this.loggerService.log('[plugin]::onPluginError', event);

    // disable mouse access on the iframe
    this.pluginService.applyFrameInteractive(false);

    // any existing api subscription should be cancelled.
    this.onErrorAPISubscription?.unsubscribe();

    // retrieve the error API from the store
    this.onErrorAPISubscription = this.pluginRepository.onErrorAPI$
      .pipe(
        // only once
        take(1),
        // with the onErrorAPI provided make a request to the server.
        switchMap((onErrorAPI) => {
          return this.pluginService.requestOnErrorAPI(onErrorAPI, event);
        }),
      )
      .subscribe((response) => {
        // with the response from the server
        if (response?.destination) {
          // navigate to the provided link.
          this.appService.openLink(response.destination);
        }
      });
  }

  /**
   * Occurs after the Iframe has resized. Informs the plugin of resize events.
   *
   * @param resizeEvent - the resize event containing the height
   */
  private onPluginResize(resizeEvent: IFrameEventResize) {
    // log
    this.loggerService.log('[plugin]::onPluginResize', resizeEvent);
    if (resizeEvent?.data?.height) {
      this.pluginService.applyContentHeight(resizeEvent.data.height);
    }
  }

  ///////////////////////////////////////////////////////////////////////////
  // FRAME DATA PROCESSING
  ///////////////////////////////////////////////////////////////////////////

  /**
   * Process an Unknown system event.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private processUnknownEvent(event: IMessage<string, any>) {
    // log
    this.loggerService.log('[plugin]::onPluginUnknownEvent', event);

    // this is a custom event type, so we pass it to the server if possible
    this.onEventAPISubscription?.unsubscribe();

    // retreive the onEventAPI
    this.onEventAPISubscription = this.pluginRepository.onEventAPI$
      .pipe(
        // this only needs to happen once
        take(1),
        switchMap((onEventAPI) => {
          // send this to the server
          return this.pluginService.requestOnEventAPI(onEventAPI, event.data);
        }),
      )
      .subscribe();
  }

  /**
   * Process a {@link IFrameEventCreateDialog} Event.
   *
   * This contains information about displaying a dialog. When the dialog
   * has completed, return a receipt.
   */
  private processFrameCreateDialog(event: IFrameEventCreateDialog) {
    // log
    this.loggerService.log('[plugin]::onPluginCreateDialog', event);

    // convert the simple event into a full JSON API suitable for the app
    const dialogConversion = ConvertPluginToDialog(event.data);

    // if there is a dialog.
    if (dialogConversion) {
      // the dialog is created and once closed, a receipt is sent.
      this.dialogService
        // open the dialog.
        .openDialog(dialogConversion)
        // listen for the dialog to close.
        .afterClosed()
        // we only need once of these.
        .pipe(take(1))
        .subscribe((data) => {
          // if there is an iframe and event id.
          if (this.frame) {
            // the frame should always recieve focus.
            this.frame.focus();
            // and only omit a recipt when requested.
            if (event.id) {
              // create a dialog receipt.
              const dialogReceipt: IFrameReceiptCreateDialog = {
                data: {
                  result: data,
                },
                id: event.id,
                type: 'dialog',
              };
              // send the receipt to the frame.
              this.frame.sendReceipt(dialogReceipt);
            }
          }
        });
    }
  }

  /**
   * Process a {@link IFrameEventCreateSnackbar} Event.
   *
   * This contains information about displaying a snackbar. When the snackbar
   * has completed, return a receipt.
   */
  private processFrameCreateSnackbar(event: IFrameEventCreateSnackbar) {
    // log
    this.loggerService.log('[plugin]::onPluginCreateSnackbar', event);

    // convert the simple event into a full JSON API suitable for the app.
    const snackbarConversion = ConvertPluginToSnackbar(event.data);

    // if there is a dialog.
    if (snackbarConversion) {
      // the dialog is created and once closed, a receipt is sent.
      this.snackbarService
        // open the snackbar.
        .open(snackbarConversion)
        // listen for when it is dismissed.
        .afterDismissed()
        // we only need one of these.
        .pipe(take(1))
        .subscribe(() => {
          // if there is a frame and event id.
          if (this.frame && event.id) {
            // create a snackbar receipt.
            const snackbarReceipt: IFrameReceiptCreateSnackbar = {
              id: event.id,
              type: 'snackbar',
            };
            // and sent it to the frame.
            this.frame.sendReceipt(snackbarReceipt);
          }
        });
    }
  }

  /**
   * Process a {@link IFrameEventCreateText} Event.
   *
   * This contains information about displaying a piece of text. When the text has
   * completed the animation, return a receipt.
   */
  private processFrameCreateText(event: IFrameEventCreateText) {
    // log
    this.loggerService.log('[plugin]::onPluginCreateText', event);

    // if there is a text renderer available
    if (this.textRenderer) {
      // listen out for when the piece of text has completed.
      const subscription = this.textRenderer.childDestroyed.subscribe((id) => {
        // if the completed text is this one.
        if (id === event.id) {
          // the subscription can be cancelled.
          subscription?.unsubscribe();
          // if there is a frame and event id.
          if (this.frame && event.id) {
            // create a text reciept.
            const textReceipt: IFrameReceiptCreateText = {
              id: event.id,
              type: 'text',
            };
            // and sent it to the frame.
            this.frame.sendReceipt(textReceipt);
          }
        }
      });
      // finally create the new piece of text.
      this.textRenderer.add(event.data, event.id);
    }
  }

  /**
   * Process a {@link IFrameEventCreateEffect} Event.
   *
   * This contains information about displaying a graphical effect.
   */
  private processFrameCreateEffect(event: IFrameEventCreateEffect) {
    // log
    this.loggerService.log('[plugin]::onPluginCreateEffect', event);
    if (event?.data?.effects && event.data.effects.length > 0) {
      // create a configuration object for the effect
      const effectConfig: IShellEffects = {
        fg: event.data.effects,
      };

      // create the effect which will return the estimated time it takes for the effect to complete
      const effectsTime = this.effectService.effect(effectConfig);

      // update the store
      this.pluginService.applyEffectDelay(effectsTime);
    }
  }

  /**
   * Process a {@link IFrameEventDBAttemptsIconData} object.
   */
  private processFrameAttemptsIcon(data: IFrameEventDBAttemptsIconData) {
    // if there is incoming data
    if (data) {
      // validate the info
      if (data.state) {
        // update any databox
        this.databox?.attemptsIconUpdate(data.state);
      }
    }
  }

  /**
   * Process a {@link IFrameEventDBHighScoreData} object.
   */
  private processFrameHighScore(data: IFrameEventDBHighScoreData) {
    // if there is incoming data
    if (data) {
      // validate the info
      if (data.score >= 0) {
        // update any databox
        this.databox?.highScoreUpdate(data.score);
      }
    }
  }

  /**
   * Process a {@link IFrameEventDBLivesIconData} object.
   */
  private processFrameLivesIcon(data: IFrameEventDBLivesIconData) {
    // if there is incoming data
    if (data) {
      // validate the info
      if (data.remaining >= 0) {
        // update any databox
        this.databox?.livesIconUpdate(data.remaining);
      }
    }
  }

  /**
   * Process a {@link IFrameEventDBProgressionIconData} object.
   */
  private processFrameProcessIcon(data: IFrameEventDBProgressionIconData) {
    // if there is incoming data
    if (data) {
      // validate the info
      if (data.current >= 0) {
        // update any databox
        this.databox?.progressIconUpdate(data.current, data.total);
      }
    }
  }

  /**
   * Process a {@link IFrameEventDBProgressionNumericData} object.
   */
  private processFrameProgressionNumeric(data: IFrameEventDBProgressionNumericData) {
    // if there is incoming data
    if (data) {
      // validate the info
      if (data.current >= 0) {
        // update any databox
        this.databox?.progressNumericUpdate(data.current, data.total);
      }
    }
  }

  /**
   * Process a {@link IFrameEventDBScoreData} object.
   */
  private processFrameScore(data: IFrameEventDBScoreData) {
    // if there is incoming data
    if (data) {
      // validate the score
      if (data.score >= 0) {
        // update any databox
        this.databox?.scoreUpdate(data.score);
        // update the score within the store to work with any possible configured heartbeat.
        this.pluginService.applyHeartbeatScore(data.score);
      }
    }
  }

  /**
   * Update the Databox Timer.
   *
   * @param data - {@link IFrameEventDBTimerData} object.
   */
  private processFrameTimer(data: IFrameEventDBTimerData) {
    if (data) {
      this.databox?.timeUpdate(data.seconds);
    }
  }
}
